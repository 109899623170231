import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import {imagePath} from '../actions/ImagePath';
import GBLVAR from '../GlobalVariables/globalvaribles';
import dataservice from '../Services/DataService';
const CryptoJS = window.CryptoJS;

var CryptoJSAesJson = {
	'stringify': function (cipherParams) {
		var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
		if (cipherParams.iv) j.iv = cipherParams.iv.toString()
		if (cipherParams.salt) j.s = cipherParams.salt.toString()
		return JSON.stringify(j).replace(/\s/g, '')
	},
	'parse': function (jsonStr) {
		var j = JSON.parse(jsonStr)
		var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
		if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
		if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
		return cipherParams
	}
}
class SamsungPay extends Component {
    constructor (props) {
      super(props);
      this.state = {
        successdata:{},
        cancelMessage:"",
        paydetails:{},
        ref_id:"",
        App_Details:{},
        orderData:{},
        paymentToken:{},
        App_Details_enable:false,
        headerState:true,
        product_id:"",
        tutelar_payload_state:""
      }
      this.completePayment = this.completePayment.bind(this);
      this.goBack = this.goBack.bind(this);
      this.encryptMethod = this.encryptMethod.bind(this);
      this.handler = this.handler.bind(this);
      this.loadTutelarSdk = this.loadTutelarSdk.bind(this);
      this.loadSamsungPay = this.loadSamsungPay.bind(this);
    }
    handler() {
		this.setState({
		  headerState: false
		})
    }
    goBack(){
        this.props.history.push(GBLVAR.PAGE_NAME.Home+'?refid='+this.props.App_Details.transaction_no);
    }
    encryptMethod(text) {
		let encrypted = CryptoJS.AES.encrypt(JSON.stringify(text), process.env.REACT_APP_KEY, { format: CryptoJSAesJson }).toString();
		return encrypted;
	}
    completePayment(){
        var _this = this;
        var getUrlParams = new URLSearchParams(window.location.search);
        var payload_option = getUrlParams.get('payload')
        console.log(payload_option);
        var data = {
            "order": _this.encryptMethod(_this.state.orderData),
        }
        var headers = {
            "secret_key" : _this.state.secret_key
        }
        data.samsung_pay_token = _this.state.samsung_pay_token;
        // data.payload = _this.state.payload;
        if(payload_option=="seon") {
            data.payload = this.state.payload;
        }
        else{
            try{
                data.tutelar_payload = this.state.tutelar_payload_state;
                data.payload = this.state.payload;	
            } catch(err){
                data.payload = this.state.payload;
            }
            
        }
        data.fraud_check = "enabled";
        dataservice.postValues(GBLVAR.API_URL, GBLVAR.API_LIST.CHARGE_REQUEST, data , {})
			.then(function (response) {
				if (response !== 422 && response.data === undefined) {
					_this.setState({ loaderRun: false, showCard: "samsung_pay_screen", Error_Message: "Please check your Samsung Pay is active or not" }, () => {
						
					});
				} else if (response === 422) {
					_this.props.history.push(GBLVAR.PAGE_NAME.CardCancel);
				} else {
					if (response.data.success == 1) {
						_this.setState({ loaderRun: false }, () => {
                            _this.setState({ payment_response: response.data }, () => {
                                if (_this.state.payment_response.threed_secure == 0 && _this.state.payment_response.success == 1) {
                                    _this.props.history.push(GBLVAR.PAGE_NAME.Directsuccess+'/'+_this.state.itemId+'?transaction_no='+_this.state.payment_response.transaction_no+'&ord_id='+_this.state.App_Details.reference_no);
								}
								else {
									_this.props.history.push(GBLVAR.PAGE_NAME.Directcancel+'/'+_this.state.itemId+'?transaction_no='+_this.state.payment_response.transaction_no+'&ord_id='+_this.state.App_Details.reference_no);
                                }
                            })
                        });
                    }
                }
            });
    }

    loadTutelarSdk(){
		var self = this;
		let tutelarRef = new window.Tutelar({ public_key: "tutpk_live_fySLEqoxgW7F" });
		tutelarRef.then((resp) => {
			self.setState({tutelar_payload_state:resp.getClientDetails()})
		}).catch((err) => {
			console.log(err);
			self.setState({tutelar_payload_state:err})
		});
	}
    
    loadSamsungPay(){
        var _this = this;
        var getUrlParams = new URLSearchParams(window.location.search);
        var urlParams = getUrlParams.get('ref_id');
        var orderdata = getUrlParams.get('orderdata');
        var transaction_no = getUrlParams.get('transaction_no');
        var getOrderData = JSON.parse(decodeURI(orderdata));
        _this.setState({product_id:getUrlParams.get('product_id'),itemId:getOrderData.transaction.product_id}, ()=>{ 
            window.app_details = getOrderData;
            console.log("sdfsd",getOrderData)
            document.documentElement.style.setProperty('--main-theme-color','#'+getOrderData?.merchant?.theme_color);
            document.documentElement.style.setProperty('--main-primary-color','#'+getOrderData?.merchant?.primary_color);
            _this.setState({ref_id:urlParams,orderdata:orderdata,transaction_no:transaction_no,App_Details_enable:true}, () => {
                _this.setState({
                    App_Details: {
                        "payment_gateway": getOrderData.merchant.payment_gateway,
                        "logo" : getOrderData.merchant.payment_screen_logo,
                        "business_name": getOrderData.merchant.business_name,
                        "transaction_no" : getOrderData.transaction_no,
                        "currency": getOrderData.currency,
                        "amount" : getOrderData.amount,
                        "description": getOrderData.description,
                        "receipt_no": getOrderData.receipt_no,
                        "reference_no": getOrderData.transaction.reference_id,
                        "created" : getOrderData.created
                    },
                    orderData: {
                        "customer" : getOrderData.customer,
                        "transaction" : {
                            "reference_id": getOrderData.transaction.reference_id,
                            "success_url" : GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success + '?refid=' +  _this.state.transaction_no,
                            "cancel_url": GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel + '?refid=' + _this.state.transaction_no,
                        }
                    }
                }, () => {
                    var data = {
                        "payment_reference" : _this.state.ref_id
                    }
                    dataservice.postValues(GBLVAR.GET_SAMSUNG_TOKEN, '', data , {})
                    .then(function (response) {
                        _this.setState({samsung_pay_token:response.data.payment_response}, () => {
                            window.seon.config({
                                session_id: `"${_this.state.transaction_no}"`,
                                audio_fingerprint: true,
                                canvas_fingerprint: true,
                                webgl_fingerprint: true,
                                onSuccess: function(message) {
                                    console.log("success", message);
                                    window.seon.getBase64Session(function(data) {
                                        if (data) {
                                            console.log("Session payload", data);
                                            _this.setState({ payload : data }, () => {
                                                _this.completePayment();
                                            });
                                        } else {
                                            _this.props.history.push(GBLVAR.PAGE_NAME.CardCancel+'/'+_this.state.App_Details.reference_id);
                                            console.log("Failed to retrieve session data.");
                                        }
                                    });
                                },
                                onError: function(message) {
                                    console.log("error", message);
                                }
                            });
                        })
                    });
                });
            })
        })
    }

    componentDidMount() {
        this.loadTutelarSdk();
        this.loadSamsungPay();
    }

    
    render() {
        return (
            <>
            <div className="fpl_body">
				<div className="fpl_body_center">
                    {/* {this.state.App_Details_enable &&
                    <Header App_Details_from_SamsungPay={this.state.App_Details} />
                    } */}
                    <div className="col-xs-12 col-sm-7 col-md-7">
                    {this.state.product_id !== '' &&
                   <Header handler={this.handler} ref_id={this.state.product_id} ord_id={this.props.product_id} payable={this.state.amount} />   		
                    }
                    </div>
                    <div className="col-xs-12 col-sm-5 col-md-5">
                    <div className="fpl_merchant_right">
                        <div className="fpl_merchant_rightTop">
							<div className="fpl_merch_detail">Payment Details</div>
						</div>
                        <div className="fpl_merchant_rightMid">
                            <div className="fl_pay_section text-center bg_remove">
                                <div className="status_heading"> Payment Processing</div>
                                <div className="status_trans_icon">   
                                    <img src={imagePath('./loader.gif')} />
                                </div>
                                <div className="status_trans_message">Kindly DO NOT close, refresh or click back button.</div>
                            </div>
                        </div>
                        <div className="fpl_merchant_rightBot">
							<img className="pci_icon" src={imagePath('./pci.png')} alt=""/>
							<img src={imagePath('./visa.png')} alt=""/>
							<img src={imagePath('./master.png')} alt=""/>
							<img src={imagePath('./amex.png')} alt=""/>
						</div>
                    </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

const mapStateToProps = state => {
	return {
		App_Details : state.AppReducer.App_Details
	}
};
          
export default connect(mapStateToProps)(SamsungPay);





