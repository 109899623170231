import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import {imagePath} from '../actions/ImagePath';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import { GET_DETAILS } from '../constants/actionTypes';
import axios from 'axios';

class Directsuccess extends Component {
    constructor (props) {
      super(props);
      this.state = {
        payment_transaction_id:"",
        transaction_no:"",
        reference_id:"",
        customerData:null,
        data: {
            'reference_id': this.props.match.params.id
        },
        headerState:true,
        product_id:"",
        amount:window.parent.amount,
        orderTotal:window.parent.orderTotal,
      }
      this.handler = this.handler.bind(this)
    }
    
	handler() {
		this.setState({
		  headerState: false
		})
    }
    componentDidMount = async() => {
       
        var _this=this;
        var urlParams = new URLSearchParams(window.location.search);
        this.setState({product_id:urlParams.get('ord_id')})
        if(urlParams.get('transaction_no')!==null){
            if(this.props.state.app_details?.payment_gateway === "ngenius"){
                document.documentElement.style.setProperty('--main-theme-color',this.props.state.app_details?.theme_color);
                document.documentElement.style.setProperty('--main-primary-color',this.props.state.app_details?.primary_color);
            }
            _this.setState({transaction_no:urlParams.get('transaction_no')}); 
        }
        else{   
            const getdetails = this.props.state.app_details;
            if(getdetails?.payment_gateway === "ngenius"){
                document.documentElement.style.setProperty('--main-theme-color',getdetails?.theme_color);
                document.documentElement.style.setProperty('--main-primary-color',getdetails?.primary_color);
            }
        }
        var data = {
            'reference_id':this.props.match.params.id
        }      
      await  dataservice.getValues(GBLVAR.API_URL,GBLVAR.API_LIST.CHECK_TOKEN+'/'+this.state.data.reference_id, {})
        .then((response)=>{
            /* if(urlParams.get('transaction_no')!==''){
            _this.setState({transaction_no:response.data.payment_data.transaction_no}); 
            } */
              this.props.getDetails(response).then(()=>{
                const getdetails = this.props.state.app_details;
                setTimeout(() =>{
                } ,1000)
            
                
                if(getdetails?.payment_gateway === "ngenius"){
                    document.documentElement.style.setProperty('--main-theme-color',getdetails?.theme_color);
                    document.documentElement.style.setProperty('--main-primary-color',getdetails?.primary_color);
                }
                var dataSuccess = {"status":"success","data": {"payment_status": "Transaction Success"}}
                window.top.parent.postMessage(dataSuccess, "*")
            })
        })
         
        
    }
    render() {
        const getdetails = this.props.state.app_details;
        return (
            <div className="fpl_body">
                <div className="fpl_body_center">
                    <div className="col-xs-12 col-sm-7 col-md-7">
                    {this.state.product_id !== '' &&
                   <Header handler={this.handler} ord_id={this.state.product_id} ref_id={this.state.data.reference_id} payable={this.state.amount} />   		
                    }
                    </div>

                    <div className="col-xs-12 col-sm-5 col-md-5">
                    <div className="fpl_merchant_right">
                        <div className="fpl_merchant_rightTop">
                            {this.state.customerData &&
                            <div className="relative">
								<div className="fpl_merch_detailName">{this.state.customerData.name}</div>
								<div className="fpl_mob_wrap">
									<div className="fpl_merch_detailMob">{this.state.customerData.phone_code+this.state.customerData.phone_number}</div>
									<span>|</span>
									<div className="fpl_merch_detailEmail">{this.state.customerData.email}</div>
								</div>
							</div>
                            }
                        </div>
                        <div className="fpl_merchant_rightMid text-center">
                            <div className="status_heading">Payment Completed</div>
                            <p className="status_trans_id">Payment ID: #{this.state.transaction_no}</p>
                            <div className="status_trans_icon">
                            <img className="successPayimg" src={imagePath('./success.svg')} />
                            </div>
                            <div className="status_trans_message">You have successfully paid {this.state.amount?this.state.amount:this.state.orderTotal}</div>
                            <div className="fpl_mob_wrap"><a className="status_done_btn">Done</a></div>
                        </div>
                        <div className="fpl_merchant_rightBot">
							<img className="pci_icon" src={imagePath('./pci.png')} alt=""/>
							<img src={imagePath('./visa.png')} alt=""/>
							<img src={imagePath('./master.png')} alt=""/>
							<img src={imagePath('./amex.png')} alt=""/>
						</div>
                    </div>
                    </div>
                </div>
            <Footer />	
        </div>
        );
    }
}
          
const mapStateToProps = state => {
	return {
		state : state.AppReducer
	}
};

const mapDispatchToProps = dispatch => ({
	getDetails: (response) => {dispatch({ type: GET_DETAILS, payload: response });return Promise.resolve();}
});  
          
export default connect(mapStateToProps,mapDispatchToProps)(Directsuccess);
