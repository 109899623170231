import React, { Component } from 'react';
import { connect } from 'react-redux';
import {imagePath} from '../actions/ImagePath';

class LinkPaid extends Component {
    constructor (props) {
        super(props);
        this.state = {
            
        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <div className="loading_image_wrapper">
                <div className="loading_image_inner">   
                    <img className="cancel_img" src={imagePath('./cancel.svg')} />
                    <p className="link_paid_txt">Link Already Paid or Invalid Link </p>
                    <div className="flp_powered">Powered by <img src={imagePath('./logo.png')} alt=""/></div>
                </div>
            </div>
        );
    }
}
          
const mapStateToProps = state => {
	return {
		state : ""
	}
};
          
export default connect(mapStateToProps)(LinkPaid);