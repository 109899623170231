import React, { Component } from 'react';
import { connect } from 'react-redux';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import { GET_DETAILS } from '../constants/actionTypes';
import {imagePath} from '../actions/ImagePath';
import Header from './Header';
import Footer from './Footer';

class Cardcancel extends Component {
    constructor (props) {
      super(props);
      this.state = {
        successdata:{},
        cancelMessage:"",
        paydetails:{},
        paylinkid:this.props.match.params.id,
        headerState:true,
        product_id:""

      }
      
      this.handler = this.handler.bind(this);
    }
    
    handler() {
		this.setState({
		  headerState: false
		})
    }
    componentDidMount() {
        this.setState({product_id:this.state.paylinkid})
        if(this.props.state.app_details?.user?.payment_gateway === "ngenius"){
            document.documentElement.style.setProperty('--main-theme-color',this.props.state.app_details?.user?.theme_color);
            document.documentElement.style.setProperty('--main-primary-color',this.props.state.app_details?.user?.primary_color);
        }
        var data = {
            'reference_id':this.props.match.params.id
        }
        dataservice.getValues(GBLVAR.API_URL, GBLVAR.API_LIST.CHECK_TOKEN+'/'+this.state.paylinkid, {})
        .then((response)=>{
            this.props.getDetails(response).then(()=>{
                const getdetails = this.props.state.app_details;
                if(getdetails?.user?.payment_gateway === "ngenius"){
                    document.documentElement.style.setProperty('--main-theme-color',getdetails?.user?.theme_color);
                    document.documentElement.style.setProperty('--main-primary-color',getdetails?.user?.primary_color);
                }
            })
        }) 
    }
    render() {
        const getdetails = this.props.state.app_details;
        return (
            <div className="fpl_body">
                <div className="fpl_body_center">
                <div className="col-xs-12 col-sm-7 col-md-7">
                    {this.state.product_id !== '' &&
                   <Header handler={this.handler} ref_id={this.state.product_id} ord_id={this.state.paylinkid} payable={this.state.amount} />   		
                    }
                    </div>		
                    <div className="col-xs-12 col-sm-5 col-md-5">
                    <div className="fpl_merchant_right">
                        <div className="fpl_merchant_rightTop">
                            <div className="relative">
                                <div className="flp_pay_detailWrap">
                                    <div className="flp_pay_detailTxt">Payment Details</div>
                                    <div className="flp_pay_detailCard">Amex, Visa & Mastercard</div>
                                </div>
                            </div>
                        </div>
                        <div className="fpl_merchant_rightMid text-center">
                            <div className="status_heading">Payment Cancelled</div>
                            <div className="status_trans_icon">
                            <img className="successPayimg" src={imagePath('./cancel.svg')} />
                            </div>
                            <div className="status_trans_message">Note : Your card not supported</div>
                            <div className="fpl_mob_wrap"><a href={GBLVAR.API_URL,GBLVAR.USER_URL+'/pay/'+this.state.paylinkid} className="status_error_btn">Try Again</a></div>
                        </div>
                        <div className="fpl_merchant_rightBot">
                            <img className="pci_icon" src={imagePath('./pci.png')} alt=""/>
                            <img src={imagePath('./visa.png')} alt=""/>
                            <img src={imagePath('./master.png')} alt=""/>
                            <img src={imagePath('./amex.png')} alt=""/>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer />	
            </div>
        );
    }
}


          
const mapStateToProps = state => {
	return {
		state : state.AppReducer
	}
};
const mapDispatchToProps = dispatch => ({
	getDetails: (response) => {dispatch({ type: GET_DETAILS, payload: response });return Promise.resolve();}
}); 

export default connect(mapStateToProps,mapDispatchToProps)(Cardcancel);