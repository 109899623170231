import React, { Component } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { GET_DETAILS, CURRENT_COUNTRY, COUNTRY_DETAIL, CHANGE_VALUE, ERROR_CHANGE, UPDATE_HEADER, SET_ERROR, CHECK_CHANGE } from '../constants/actionTypes';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import Header from './Header';
import Loader from './Loader';
import Footer from './Footer';
import { imagePath } from '../actions/ImagePath';
import PhoneCode from './PhoneCode';
import { StateList } from './StateList';
import { Countries } from './countries';
import Select from 'react-select';
import Cleave from 'cleave.js/react';
import MobileTopHeader from './MobileTopHeader';
import { returnCountryInfo, getInfo, returnCodeInfo } from "../Services/Utils";
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import axios from 'axios';

let taxvalue;
const CryptoJS = window.CryptoJS;
let click2payInstance;
let click2payEnable = false;
try{
	click2payInstance =  new window.Click2Pay();
	click2payEnable = true;
} catch(err) {
	click2payEnable = false;
}

let encryptedCard, cardBrand, checkoutWithNewCard;

var CryptoJSAesJson = {
	'stringify': function (cipherParams) {
		var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
		if (cipherParams.iv) j.iv = cipherParams.iv.toString()
		if (cipherParams.salt) j.s = cipherParams.salt.toString()
		return JSON.stringify(j).replace(/\s/g, '')
	},
	'parse': function (jsonStr) {
		var j = JSON.parse(jsonStr)
		var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
		if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
		if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
		return cipherParams
	}
}
var cardNumber = "";
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				'reference_id': this.props.match.params.id
			},
			productQuantity:"",
			paymentTokenStatus: false,
			paymentToken: {},
			payload : "",
			currentIp:"",
			count:1,
			active:false,
			qtyMinus:false,
			qtyPlus:true,
			amount:null,
			vat_amount:null,
			shipping_amount:null,
			order_tax:[],
			product_images:[],
			dataList:[],
			headerState:true,
			homeState:true,
			productValue:"",
			succespage:this.props.state.app_details,
			trans_amount:null,
			isloading:true,
			learnmore:false,
			showCard:"",
			otpErrorReason:"",
			click2paySavedCardList:[],
			click2paySavedCardListShow:false,
			click2payToken:{},
			disablec2p:true,
			tutelar_payload_state:""

		}
		this.handleGuestEvent = this.handleGuestEvent.bind(this);
		this.handleKeyEvent = this.handleKeyEvent.bind(this);
		this.handlePaste = this.handlePaste.bind(this);
		this.guestLogin = this.guestLogin.bind(this);
		this.guesthandleKeyPress = this.guesthandleKeyPress.bind(this);
		this.validateEmail = this.validateEmail.bind(this);
		this.checkNameFormat = this.checkNameFormat.bind(this);
		this.onCreditCardTypeChanged = this.onCreditCardTypeChanged.bind(this);
		this.onCreditCardChange = this.onCreditCardChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.handleExpiryMonthYear = this.handleExpiryMonthYear.bind(this);
		this.handleOnCvvChange = this.handleOnCvvChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.postalCodeChange = this.postalCodeChange.bind(this);
		this.showErrors = this.showErrors.bind(this);
		this.setValidFields = this.setValidFields.bind(this);
		this.encryptMethod = this.encryptMethod.bind(this);
		this.setValidPostal = this.setValidPostal.bind(this);
		this.applePay = this.applePay.bind(this);  
		this.fraudCheck = this.fraudCheck.bind(this);
		this.samsungPay = this.samsungPay.bind(this);
		this.handlePhone = this.handlePhone.bind(this);
		this.handleState = this.handleState.bind(this);
		this.handler = this.handler.bind(this);
		this.loadTutelarSdk = this.loadTutelarSdk.bind(this);
	}
	handler() {
		this.setState({
		  headerState: false
		})
	  }
	
	samsungPay(){
		var _this = this;
		var send_detail = _this.props.state;
		const [cc_first_name, cc_last_name] = this.props.state.name.split(' ');
		
		var orderData = {
			"requestId" : "FLAT"+uuidv4().slice(0,4),
			"customer": {
				'email': this.props.state.email,
				'name': this.props.state.name,
				'phone_number': this.props.state.ph_number,
				'phone_code': send_detail.selected_country?.country_code || this.props.state.selectedCountry.code,
				"address": send_detail.billing_address || this.props.state.current_country.city,
				"city": send_detail.billing_city || this.props.state.current_country.city,
				"state": send_detail.state || this.props.state.current_country.regionName,
				"country": send_detail.selectedCountry.value || this.props.state.selectedCountry.value,
				"postal_code": send_detail.postal_code == '0' ? '000000' : send_detail.postal_code || this.props.state.current_country.zip
			},
			"transaction": {
				
				"reference_id": this.state.order_id,
				"product_id":this.state.referenceid
			},
			"merchant" : {
				"business_name": send_detail?.app_details?.user?.business_name,
				"payment_gateway": send_detail?.app_details?.user?.payment_gateway,
				"payment_screen_logo" : send_detail?.app_details?.user?.payment_screen_logo,
				"theme_color": send_detail.app_details.user.theme_color ? send_detail.app_details.user.theme_color.replace('#','') : '0769a6',
				"primary_color": send_detail.app_details.user.primary_color ? send_detail.app_details.user.primary_color.replace('#','') : '0d0d0d',
			},
			"currency": send_detail.app_details.currency,
			"amount" : _this.state.trans_amount
		}
		var data = {
			
			"access_from":"product",
			"reference_id":this.state.order_id,
			"callback_url": GBLVAR.USER_URL+GBLVAR.PAGE_NAME.SamsungPay+"?orderdata="+encodeURI(JSON.stringify(orderData))+'&transaction_no='+send_detail.app_details.transaction_no+'&ord_id='+this.state.order_id+'&payload='+send_detail.app_details.payload_option
		}
		
		dataservice.postValues(GBLVAR.SAMSUNG_PAY, '', data, {})
		.then(function (response) {
			var result = response.data.order_response;
			window.SamsungPay.connect(result.id, result.href, '2a1e5396e0764408864eea', GBLVAR.USER_URL+GBLVAR.PAGE_NAME.SamsungPay+"?orderdata="+encodeURI(JSON.stringify(orderData))+'&transaction_no='+send_detail.app_details.transaction_no+'&order_id='+
			_this.state.order_id+'&product_id='+
			_this.state.data.reference_id+'&payload='+send_detail.app_details.payload_option, GBLVAR.USER_URL+GBLVAR.PAGE_NAME.SamsungCancel+"?orderdata="+encodeURI(JSON.stringify(orderData))+'&transaction_no='+send_detail.app_details.transaction_no+'&order_id='+
			_this.state.order_id+'&product_id='+
			_this.state.data.reference_id+'&payload='+send_detail.app_details.payload_option, 'en_ae', result.encInfo.mod, result.encInfo.exp, result.encInfo.keyId);
		});
	}
	applePay() {
		var _this = this;
		var send_detail = _this.props.state;
		var runningTotal = _this.state.trans_amount;
		var data;
		var paymentRequest = {
			currencyCode: 'AED',
			countryCode: 'AE',
			total: {
				label: 'FOLOOSI',
				amount: runningTotal
			},
			supportedNetworks: ['masterCard', 'visa'],
			merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit']
		};
		var session = new window.ApplePaySession(6, paymentRequest);
		session.onvalidatemerchant = function (event) {
			//var promise = this.performValidation(event.validationURL);
			data = {
		
				'validation_url': event.validationURL,
				'access_from': 'product',
				
			}
			if(_this.props.state.app_details?.user?.payment_gateway == 'ngenius'){
				data.payment_gateway = 'ngenius'
			}
			var promise = axios({
				method: 'post',
				url: GBLVAR.APPLE_PAY + 'applepay/request-token',
				data: data
			}).then(function (response) {
				return response.data.data;
			})
			.catch(function (error) {
				console.log(error);
			});
			//console.log(promise);
			promise.then(function (merchantSession) {
				session.completeMerchantValidation(merchantSession);
			});
		}
		session.onpaymentmethodselected = function (event) {
			var newTotal = { type: 'final', label: 'Foloosi', amount: runningTotal };
			var newLineItems = [];
			session.completePaymentMethodSelection(newTotal, newLineItems);
		}
		session.onpaymentauthorized = function (event) {
			//var promise = this.sendPaymentToken(event.payment.token);
			var paymentToken = event.payment.token;
			var promise = new Promise(function (resolve, reject) {
				_this.setState({ paymentToken: paymentToken });
				return resolve(true);
			});
			promise.then(function (success) {
				var status;
				if (success) {
					status = window.ApplePaySession.STATUS_SUCCESS;
					_this.setState({ paymentTokenStatus: true }, () => {
						_this.paynow('apple_pay');
					});
				} else {
					status = window.ApplePaySession.STATUS_FAILURE;
				}
				session.completePayment(status);
			});
		}
		session.oncancel = function (event) {
			//console.log(event);
			_this.props.history.push(GBLVAR.PAGE_NAME.CardCancel+'/'+_this.props.match.params.id+'?ord_id='+this.state.order_id);
		}
		session.begin();
	}
	encryptMethod(text) {
		let encrypted = CryptoJS.AES.encrypt(JSON.stringify(text), process.env.REACT_APP_KEY, { format: CryptoJSAesJson }).toString();
		return encrypted;
	}
	showErrors() {
		var state = this.props.state;
		var cardname = state.validCardName === true ? 'Name, ' : '';
		var carnumber = state.cardNumberValid === true || state.cardNumberValid === undefined ? 'Card number, ' : '';
		var cardexpiry = state.expiry_common === true || state.expiry_common === undefined ? 'Expiry, ' : '';
		var cardcvv = state.cardCvvValid === true || state.cardCvvValid === undefined ? 'CVV, ' : '';
		this.props.setCountryDetail({ Error_Message: `${'Please fill ' + cardname + carnumber + cardexpiry + cardcvv + 'fields below'}` });
	}
	paynow(is_from) {
		var headers = {
			'is_encrypted': true
		}
		var shippingInfo;
		var send_detail = this.props.state;
		console.log(send_detail,"adda");
		this.props.updateCredential(headers).then(() => {
			this.props.setCountryDetail({ loderRun: true, showCard: "" });

			const [cc_first_name, cc_last_name] = this.props.state.name.split(' ');
			let browserDetails = {
				"deviceChannel": "BRW",
				"threeDSCompInd": "Y",
				"notificationURL": GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success+'?reference_id_top='+this.state.referenceid,
				"browserInfo": {
					"browserAcceptHeader": "application\/json",
					"browserJavaEnabled": true,
					"browserLanguage": window.navigator.language || window.navigator.userLanguage,
					"browserTZ": "0",
					"browserUserAgent": window.navigator.userAgent,
					"browserColorDepth": window.screen.colorDepth,
					"browserScreenHeight": window.screen.height,
					"browserScreenWidth": window.screen.width,
					"browserJavascriptEnabled": true,
					"browserIP": this.state.currentIp ? this.state.currentIp : "0.0.0.0",
					"challengeWindowSize": "02"
				}
			}
			if (is_from === "apple_pay" && this.state.paymentTokenStatus) {
				var encrypt = {
					"requestId" : "FLAT"+uuidv4().slice(0,4),
					"customer": {
						'email': this.props.state.email,
						'name': this.props.state.name,
						'phone_number': this.props.state.ph_number,
						'phone_code': send_detail.selected_country?.country_code || this.props.state.selectedCountry.code,
						"address": send_detail.billing_address || this.props.state.current_country.city,
						"city": send_detail.billing_city || this.props.state.current_country.city,
						"state": send_detail.state || this.props.state.current_country.regionName,
						"country": send_detail.selectedCountry.value || this.props.state.selectedCountry.value,
						"postal_code": send_detail.postal_code == '0' ? '000000' : send_detail.postal_code || this.props.state.current_country.zip
					},
					"transaction": {
						"reference_id": this.state.order_id,
						'success_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success+'?reference_id_top='+this.state.referenceid+'&ord_id='+this.state.order_id,
						'cancel_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel+'?reference_id_top='+this.state.referenceid+'&ord_id='+this.state.order_id,
						
					}
				}
				var data = {
					order: this.encryptMethod(encrypt),
					apple_pay_token: this.state.paymentToken
				}
			}  else if(is_from === "click2pay"){
				var selected_country_code = this.props.state.selected_country ? this.props.state.selected_country.country_code : '+971';
				var orderData = {	
					"customer": {
						'email': this.props.state.email,
						'name': this.props.state.name,
						'phone_number': this.props.state.ph_number,
						'phone_code': send_detail.selected_country?.country_code || selected_country_code,
						"flat_no": send_detail.billing_flat_no,
						"address": send_detail.billing_address || this.props.state.current_country.city,
						"city": send_detail.billing_city || this.props.state.current_country.city,
						"state": send_detail.state || this.props.state.current_country.regionName,
						"country": send_detail.selectedCountry.value || this.props.state.selectedCountry.value,
						"postal_code": send_detail.postal_code == '0' ? '000000' : send_detail.postal_code || this.props.state.current_country.zip
					},
					"transaction": {
						"reference_id": this.state.order_id,
						'success_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success+'?reference_id_top='+this.state.referenceid+'&ord_id='+this.state.order_id,
						'cancel_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel+'?reference_id_top='+this.state.referenceid+'&ord_id='+this.state.order_id,
					},
					"card":{
						"cvv":this.state.c2p_cvv ? this.state.c2p_cvv : this.props.state.cardCvv
					},
					"browserDetails":browserDetails,
					"dpa_id": this.props.state?.dpa_id
				}
				console.log("orderData11111",orderData);
				data = {
					order: this.encryptMethod(orderData),
					click2pay_token: this.state.click2payToken
				} 
				console.log("datadatadata",data);
			} else {
				var encrypt = {
					"requestId" : "FLAT"+uuidv4().slice(0,4),
					"card": {
						'first_name': cc_first_name,
						'last_name': cc_last_name !== '' && cc_last_name !== undefined ? cc_last_name : cc_first_name,
						'card_number': send_detail.cardNumber,
						'card_exp': send_detail.cardExpiryYear + send_detail.cardExpiryMonth,
						'card_cvv': send_detail.cardCvv
					},
					"customer": {
						'email': this.props.state.email,
						'name': this.props.state.name,
						'phone_number': this.props.state.ph_number,
						'phone_code': send_detail.selected_country?.country_code || this.props.state.selectedCountry.code,
						"flat_no": send_detail.billing_flat_no,
						"address": send_detail.billing_address || this.props.state.current_country.city,
						"city": send_detail.billing_city || this.props.state.current_country.city,
						"state": send_detail.state || this.props.state.current_country.regionName,
						"country": send_detail.selectedCountry.value || this.props.state.selectedCountry.value,
						"postal_code": send_detail.postal_code == '0' ? '000000' : send_detail.postal_code || this.props.state.current_country.zip
					},
					"transaction": {
						"reference_id": this.state.order_id,
						'success_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success+'?reference_id_top='+this.state.referenceid+'&ord_id='+this.state.order_id,
						'cancel_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel+'?reference_id_top='+this.state.referenceid+'&ord_id='+this.state.order_id,
					},
					"browserDetails":browserDetails
				}
				if(send_detail.app_details.recurring_payment === "Yes"){
					encrypt.card_save = send_detail.card_save;
					encrypt.customer_unique_reference = send_detail.customer_unique_reference;
				}
				window.card_save = send_detail.card_save;
				window.customer_unique_reference = send_detail.customer_unique_reference;
				console.log("all123",encrypt)
				var data = {
					order: this.encryptMethod(encrypt),
				}
			}
			// data.payload = this.state.payload;
			if(send_detail.app_details.payload_option=="seon") {
				data.payload = this.state.payload;
			}
			else{
				try{
					data.tutelar_payload = this.state.tutelar_payload_state;
					data.payload = this.state.payload;	
				} catch(err){
					data.payload = this.state.payload;
				}
				
			}
			data.fraud_check = "enabled";
			console.log("data",data)
			dataservice.postValues(GBLVAR.API_URL, GBLVAR.API_LIST.CHARGE_REQUEST, data, this.props.state.headers)
				.then((response) => {
					
					if (response !== 422 && response.data === undefined) {
						this.props.setCountryDetail({ Error_Message: `${'Please the fields below'}`, showCard: 'card_screen', loderRun: false });
					} else if (response === 422) {
						this.props.history.push(GBLVAR.PAGE_NAME.CardCancel+'/'+this.props.match.params.id+'?ord_id='+this.state.order_id);
					} else {
						window.payment_response = response.data;
						var responseData = response.data;
						if (responseData.payment_gateway === 'paytabpci') {
							this.props.setCountryDetail({ loderRun: false, payment_response: response.data }).then(() => {
								var send_detail = this.props.state;
								if (send_detail.payment_response.threed_secure == 1) {
									this.props.setCountryDetail({ showCard: "3d Redirect" }).then(() => {
										var paymentFrameDoc = document.getElementById("foloosiPluginApiiframe").contentDocument || document.getElementById("foloosiPluginApiiframe").contentWindow.document;
										paymentFrameDoc.open();
										paymentFrameDoc.write("<html><body></body></html>");
										paymentFrameDoc.close();
										var form = paymentFrameDoc.createElement("form");
										form.setAttribute("method", "POST");
										form.setAttribute("action", send_detail.payment_response.redirect_url);
										form.setAttribute("enctype", "application/x-www-form-urlencoded");
										paymentFrameDoc.body.appendChild(form);
										form.submit();
									})
								}
								else if (send_detail.payment_response.threed_secure == 0 && send_detail.payment_response.success == 1) {
									this.props.history.push(GBLVAR.PAGE_NAME.Directsuccess+'/'+send_detail.app_details.product_no+'?transaction_no='+send_detail.payment_response.transaction_no+'&ord_id='+this.state.order_id);
								}
								else {
									this.props.history.push(GBLVAR.PAGE_NAME.Directcancel+'/'+send_detail.app_details.product_no+'?transaction_no='+send_detail.payment_response.transaction_no+'&ord_id='+this.state.order_id);
								}
							})
						}
						if (responseData.payment_gateway === 'ngenius') {
							this.props.setCountryDetail({ loderRun: false, payment_response: response.data }).then(() => {
								var send_detail = this.props.state;
								if (send_detail.payment_response.threed_secure == 1) {
									this.props.setCountryDetail({ showCard: "ngeniusthreedsecure" }).then(() => {
										var threedView = send_detail.payment_response.threed_view;
										var paymentFrameDoc = document.getElementById("foloosiPluginApiiframe").contentDocument || document.getElementById("foloosiPluginApiiframe").contentWindow.document;
										paymentFrameDoc.open();
										paymentFrameDoc.write("<html><body></body></html>");
										paymentFrameDoc.close();
										var form = paymentFrameDoc.createElement("form");
										form.setAttribute("method", "POST");
										form.setAttribute("action", threedView.acs_url);
										form.setAttribute("enctype", "application/x-www-form-urlencoded");
										var PaReq = paymentFrameDoc.createElement("input"); 
										PaReq.setAttribute("type", "hidden"); 
										PaReq.setAttribute("name", "PaReq"); 
										PaReq.setAttribute("id", "PaReq"); 
										PaReq.setAttribute("value", threedView.acs_page_req);
										var TermUrl = paymentFrameDoc.createElement("input"); 
										TermUrl.setAttribute("type", "hidden"); 
										TermUrl.setAttribute("name", "TermUrl"); 
										TermUrl.setAttribute("id", "TermUrl"); 
										TermUrl.setAttribute("value", decodeURIComponent(threedView.temp_url));
										var MD = paymentFrameDoc.createElement("input"); 
										MD.setAttribute("type", "hidden"); 
										MD.setAttribute("name", "MD"); 
										MD.setAttribute("id", "MD"); 
										MD.setAttribute("value", threedView.acs_md);
										form.appendChild(PaReq); 
										form.appendChild(TermUrl); 
										form.appendChild(MD); 
										paymentFrameDoc.body.appendChild(form);
										form.submit();
									})
								}
								else if (send_detail.payment_response.threed_secure == 2) {
									this.props.setCountryDetail({ showCard: "ngeniusthreedsecure" }).then(() => {
										var threedView = send_detail.payment_response.threed_view;
										var paymentFrameDoc = document.getElementById("foloosiPluginApiiframe").contentDocument || document.getElementById("foloosiPluginApiiframe").contentWindow.document;
										paymentFrameDoc.open();
										paymentFrameDoc.write("<html><body></body></html>");
										paymentFrameDoc.close();
										var form = paymentFrameDoc.createElement("form");
										form.setAttribute("method", "POST");
										form.setAttribute("action", threedView.acs_url);
										form.setAttribute("enctype", "application/x-www-form-urlencoded");
										var cReq = paymentFrameDoc.createElement("input"); 
										cReq.setAttribute("type", "hidden"); 
										cReq.setAttribute("name", "creq"); 
										cReq.setAttribute("id", "creq"); 
										cReq.setAttribute("value", threedView.c_req);
										form.appendChild(cReq); 
										paymentFrameDoc.body.appendChild(form);
										form.submit();
									})
								}
								else if (send_detail.payment_response.threed_secure == 0 && send_detail.payment_response.success == 1) {
									this.props.history.push(GBLVAR.PAGE_NAME.Directsuccess+'/'+send_detail.app_details.product_no+'?transaction_no='+send_detail.payment_response.transaction_no+'&ord_id='+this.state.order_id);
								}
								else {
									this.props.history.push(GBLVAR.PAGE_NAME.Directcancel+'/'+send_detail.app_details.product_no+'?transaction_no='+send_detail.payment_response.transaction_no+'&ord_id='+this.state.order_id);
								}
							})
						}
					}
				})
		})
	}
	postalCodeChange(e) {
		var postalRawValue = e.target.rawValue;
		this.setValidPostal(postalRawValue);
		var payment_error = {};
		payment_error['postal_code'] = '';
		this.props.errorChange(payment_error);
	}
	setValidPostal(value) {
		var postalRawValue = value;
		if (this.props.state.selectedCountry.value === "USA") {
			if (postalRawValue.length === 9) {
				const code = postalRawValue.slice(0, 5) + "-" + postalRawValue.slice(-4)
				this.props.setCountryDetail({ postal_code: code }).then(() => {
					var regEx = /^\d{5}-\d{4}$/;
					if (!regEx.test(this.props.state.postal_code)) {
						this.props.setCountryDetail({ valid_postal_code: true });
					}
					else {
						this.props.setCountryDetail({ valid_postal_code: false });
						// this.props.setCountryDetail(value);
					}
				})
			}
			else {
				this.props.setCountryDetail({postal_code:value, valid_postal_code: true });
			}
		}
		else {
			if (postalRawValue.length === 6) {
				const code = postalRawValue.slice(0, 3) + " " + postalRawValue.slice(-3)
				this.props.setCountryDetail({ postal_code: code }).then(() => {
					var regEx = /[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/;
					if (!regEx.test(this.props.state.postal_code)) {
						this.props.setCountryDetail({ valid_postal_code: true });
					}
					else {
						this.props.setCountryDetail({ valid_postal_code: false });
					}
				})
			}
			else {
				this.props.setCountryDetail({ valid_postal_code: true });
			}
		}
	}
	handlePhone(event) {
		this.handleGuestEvent('ph_number');
		this.props.handleChange("countryFlag", !this.props.state.countryFlag);
	}
	handleState(e){
	
		var payment_error = {};
		payment_error['state'] = '';
		this.props.setError(payment_error);
		this.props.setCountryDetail(e);
		this.props.setCountryDetail({state:e.state});
	}
	handleChange(event) {
		var error = {};
		var name = event.target.name;
		error[name] = "";
		this.props.setCountryDetail({ [name]: event.target.value }).then(() => {
			this.props.setError(error).then(() => {
				this.setValidFields(name);
			})
		})
	}
	setValidFields(name) {
		var error = {};
		if (name === "name") {
			if (this.props.state.name === "") {
				this.props.setCountryDetail({ validCardName: true });
			} else {
				if (!this.checkNameFormat(this.props.state.name, "name")) {
					error.name = 'Please Enter Valid Name, Example: Omar Ali (Only English letters allowed)';
					this.props.setError(error);
					this.props.setCountryDetail({ validCardName: true });
				} else {
					this.props.setCountryDetail({ validCardName: false });
				}
			}
		}
		if (name === "billing_flat_no") {
			var billing_flat_no = this.props.state.billing_flat_no;
			if (billing_flat_no.length >= 1 && billing_flat_no.length <= 100) {
				this.props.setCountryDetail({ valid_billing_flat_no: false });
			} else {
				error.billing_flat_no = 'Flat No. should not be empty';
				this.props.setError(error);
				this.props.setCountryDetail({ valid_billing_flat_no: true });
			}
		}
		if (name === "billing_address") {
			var billing_address = this.props.state.billing_address;
			if (billing_address.length >= 1 && billing_address.length <= 100) {
				if (!this.checkNameFormat(billing_address, "address")) {
					error.billing_address = 'Address must be contain A-Z, a-z, 0-9, space only';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_billing_address: true });
				}
				else {
					this.props.setCountryDetail({ valid_billing_address: false });
				}
			} else {
				this.props.setCountryDetail({ valid_billing_address: true });
			}
		}
		if (name === "billing_city") {
			var city = this.props.state.billing_city;
			if (city !== "") {
				if (!this.checkNameFormat(city, "check")) {
					error.billing_city = 'Only English letters and spaces are allowed';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_billing_city: true });
				}
				else {
					this.props.setCountryDetail({ valid_billing_city: false });
				}
			} else {
				this.props.setCountryDetail({ valid_billing_city: true });
			}
		}
		if (name === "state") {
			var state = this.props.state.state;
			if (state !== "") {
				if (!this.checkNameFormat(state, "check")) {
					error.state = 'Only English letters and spaces are allowed';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_state: true });
				}
				else {
					this.props.setCountryDetail({ valid_state: false });
				}
			} else {
				this.props.setCountryDetail({ valid_state: true });
			}
		}
		if (name === "postal_code") {
			var postal_code = this.props.state.postal_code;
			if (postal_code === "") {
				this.props.setCountryDetail({ valid_postal_code: true });
			} else {
				if (!this.checkNameFormat(postal_code, "postal_code")) {
					error.postal_code = 'Postal Code must be contain A-Z, a-z, 0-9, space only';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_postal_code: true });
				}
				else {
					this.props.setCountryDetail({ valid_postal_code: false });
				}
			}
		}
		/* if (name === "state") {
			var state = this.props.state.state;
			if (state !== "") {
				if (!this.checkNameFormat(state, "check")) {
					error.state = 'Only English letters and spaces are allowed';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_state: true });
				}
				else {
					this.props.setCountryDetail({ valid_state: false });
				}
			} else {
				this.props.setCountryDetail({ valid_state: true });
			}
		}
		if (name === "postal_code") {
			var postal_code = this.props.state.postal_code;
			if (postal_code === "") {
				this.props.setCountryDetail({ valid_postal_code: true });
			} else {
				if (!this.checkNameFormat(postal_code, "postal_code")) {
					error.postal_code = 'Postal Codee must be contain A-Z, a-z, 0-9, space only';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_postal_code: true });
				}
				else {
					this.props.setCountryDetail({ valid_postal_code: false });
				}
			}
		} */
		if (name === "shipping_flat_no") {
			var shipping_flat_no = this.props.state.shipping_flat_no;
			if (shipping_flat_no.length >= 1 && shipping_flat_no.length <= 100) {
				this.props.setCountryDetail({ valid_shipping_flat_no: false });
			} else {
				error.shipping_flat_no = 'Flat No. should not be empty';
				this.props.setError(error);
				this.props.setCountryDetail({ valid_shipping_flat_no: true });
			}
		}
		if (name === "shipping_address") {
			var shipping_address = this.props.state.shipping_address;
			if (shipping_address.length >= 1 && shipping_address.length <= 100) {
				if (!this.checkNameFormat(shipping_address, "address")) {
					error.shipping_address = 'Address must be contain A-Z, a-z, 0-9, space only';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_shipping_address: true });
				}
				else {
					this.props.setCountryDetail({ valid_shipping_address: false });
				}
			} else {
				this.props.setCountryDetail({ valid_shipping_address: true });
			}
		}
		if (name === "shipping_city") {
			var shipping_city = this.props.state.shipping_city;
			if (shipping_city !== "") {
				if (!this.checkNameFormat(shipping_city, "check")) {
					error.shipping_city = 'Only English letters and spaces are allowed';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_shipping_city: true });
				}
				else {
					this.props.setCountryDetail({ valid_shipping_city: false });
				}
			} else {
				this.props.setCountryDetail({ valid_shipping_city: true });
			}
		}
		if (name === "shipping_state") {
			var shipping_state = this.props.state.shipping_state;
			if (shipping_state !== "") {
				if (!this.checkNameFormat(shipping_state, "check")) {
					error.shipping_state = 'Only English letters and spaces are allowed';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_shipping_state: true });
				}
				else {
					this.props.setCountryDetail({ valid_shipping_state: false });
				}
			} else {
				this.props.setCountryDetail({ valid_shipping_state: true });
			}
		}
		if (name === "shipping_postal_code") {
			var shipping_postal_code = this.props.state.shipping_postal_code;
			if (shipping_postal_code === "") {
				this.props.setCountryDetail({ valid_shipping_postal_code: true });
			} else {
				if (!this.checkNameFormat(shipping_postal_code, "postal_code")) {
					error.valid_shipping_postal_code = 'Postal Code1 must be contain A-Z, a-z, 0-9, space only';
					this.props.setError(error);
					this.props.setCountryDetail({ valid_shipping_postal_code: true });
				}
				else {
					this.props.setCountryDetail({ valid_shipping_postal_code: false });
				}
			}
		}
	}
	handleOnCvvChange(event) {
		this.props.setCountryDetail({ cardCvv: event.target.rawValue }).then(() => {
			if (this.props.state.cardCvv.length < 3) {
				this.props.setCountryDetail({ cardCvvValid: true });
			}
			else {
				this.props.setCountryDetail({ cardCvvValid: false });
			}
		});
	}
	onDateChange(event) {
		var dateRawValue = event.target.rawValue;
		if (dateRawValue.length === 4) {
			this.props.setCountryDetail({ cardExpiryMonth: dateRawValue.slice(0, 2), cardExpiryYear: dateRawValue.slice(-2) })
		}
		if (dateRawValue.length < 4) {
			this.props.setCountryDetail({ cardExpiryMonth: "", cardExpiryYear: "", expiry_common: true })
		}
	}
	handleExpiryMonthYear(event) {
		var now = new Date();
		var currentYear = now.getFullYear().toString().substr(-2);
		var currentMonth = now.getMonth() + 1;
		currentMonth = parseInt(currentMonth);
		currentYear = parseInt(currentYear);
		if (this.props.state.cardExpiryYear !== "" && this.props.state.cardExpiryMonth !== "") {
			var expiryDateYear = parseInt(this.props.state.cardExpiryYear);
			var expiryDateMonth = parseInt(this.props.state.cardExpiryMonth);
			if (currentYear === expiryDateYear) {
				if (expiryDateMonth < currentMonth) {
					this.props.setCountryDetail({ expiry_common: true });
				} else {
					this.props.setCountryDetail({ expiry_common: false });
				}
			} else {
				if (expiryDateYear < currentYear) {
					this.props.setCountryDetail({ expiry_common: true });
				} else {
					this.props.setCountryDetail({ expiry_common: false });
				}
			}
		}
	}
	onCreditCardChange(event) {
		cardNumber = event.target.rawValue;
		this.props.setCountryDetail({ cardNumber: event.target.rawValue }).then(() => {
			var rupayRegex = /^(508[5-9]|60(80(0|)[^0]|8[1-4]|8500|698[5-9]|699|7[^9]|79[0-7]|798[0-4])|65(2(1[5-9]|[2-9])|30|31[0-4])|817[2-9]|81[89]|820[01])/;
			var discoverRegex = /^6(?:018\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})$/;
			var card_value = this.props.state.cardNumber.replace(/\s/g, '');
			if (rupayRegex.test(card_value) === true) {
				this.props.setCountryDetail({ creditCardType: 'rupay', cardNumberValid: false });
			}
			else if (discoverRegex.test(card_value) === true) {
				this.props.setCountryDetail({ creditCardType: 'discover', cardNumberValid: false });
			}
		})
	}
	onCreditCardTypeChanged(type) {
		this.props.setCountryDetail({ creditCardType: type }).then(() => {
			if (this.props.state.creditCardType == 'unknown' || this.props.state.creditCardType == 'uatp') {
				this.props.setCountryDetail({ cardNumberValid: true });
			}
			else {
				this.props.setCountryDetail({ cardNumberValid: false });
			}
		});
	}
	checkNameFormat(name, key) {
		var regName = key === "name" ? /^[a-zA-Z][a-zA-Z ]*$/ : key === "address" ? /^[a-zA-Z0-9][a-zA-Z_0-9 ]*$/ :
			key === "check" ? /^[a-zA-Z][a-zA-Z ]*$/ : /^[a-zA-Z0-9][a-zA-Z0-9 ]*$/;
		if (regName.test(name)) {
			return name;
		} else {
			return false;
		}
	}
	validateEmail(email) {
		var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
		return re.test(email);
	}
	guesthandleKeyPress(e) {
		if (e.key === 'Enter') {
			this.guestLogin();
		}
	}
	guestLogin() {
		
		window.scrollTo(0, 0);
		var guest = this.props.state;
		var errMsg = 0;
		var error = {};
		var payment_error = this.props.state.payment_error;
		var selected_country_flag = this.props.state.selected_country ? this.props.state.selected_country.country_flag : 'ae';
		var selected_country_code = this.props.state.selected_country ? this.props.state.selected_country.country_code : '+971';
		
		if(guest.email && guest.name && guest.ph_number && guest.billing_flat_no && guest.billing_city && guest.billing_address &&guest.state && guest.postal_code){
			var data = {'product_id' : this.state.data.reference_id,'quantity' : this.state.count}
			dataservice.postValues(GBLVAR.API_ORDER, '', data, {})
						.then((response) => {
							
							var {order_id} = response.data;
								this.setState({order_id:order_id})
								var {reference_id}=response.data;
								this.setState({referenceid:reference_id})
						
							if(response.code == '1001'){
								this.props.history.push(GBLVAR.PAGE_NAME.LinkPaid);
								
							} 
						})
					}
		if (!guest.email) {
			error.email = 'Please Enter Email';
			this.props.errorChange(error);
			return errMsg += 1;
			
		}
		if (guest.email) {
			if (this.validateEmail(guest.email) === false) {
				error.email = 'Email is not valid';
				this.props.errorChange(error);
				return errMsg += 1;
			}
			
		}
		if (!guest.name) {
			error.name = 'Please Enter Name';
			this.props.errorChange(error);
			return errMsg += 1;
		}
		if (!guest.name[0].match(/[a-z]/i)) {
			error.name = 'Name Starts With Letters Only';
			this.props.errorChange(error);
			return errMsg += 1;
		}
		if (guest.name) {
			if (this.checkNameFormat(guest.name, "name") === false) {
				error.name = 'Please Enter Valid Name, Example: Omar Ali (Only English letters allowed)';
				this.props.errorChange(error);
				return errMsg += 1;
			}
		}
		if (!guest.ph_number) {
			error.ph_number = 'Please Enter Phone Number';
			this.props.errorChange(error);
			return errMsg += 1;
		}
		if (guest.ph_number.charAt(0) == "0") {
			guest.ph_number = guest.ph_number.slice(1);
		}
		if (!isValidPhoneNumber(selected_country_code + guest.ph_number)) {
			error.ph_number = 'Phone Number Invalid';
			this.props.errorChange(error);
			return errMsg += 1;
		}
		/* Address Validation Added */
		if (!guest.billing_flat_no || guest.billing_flat_no!=='') {
			var billing_flat_no = guest.billing_flat_no;
			if (billing_flat_no.length >= 1 && billing_flat_no.length <= 100) {
				this.props.setCountryDetail({ valid_billing_flat_no: false });
			} else {
				payment_error.billing_flat_no = 'Flat No. should not be empty';
				this.props.setCountryDetail({ valid_billing_flat_no: true });
				this.props.errorChange(error);
				return errMsg += 1;
			}
		}
		
		if (!guest.billing_city || guest.billing_city !=="") {
			var city = guest.billing_city;
			if (city !== "") {
				if (!this.checkNameFormat(city, "check")) {
					payment_error.billing_city = 'Enter valid billing city';
					this.props.errorChange(payment_error);
					this.props.setCountryDetail({ valid_billing_city: true });
					return errMsg += 1;
				}
				else {
					this.props.setCountryDetail({ valid_city: false });
				}
			} else {
				payment_error.billing_city = 'Enter valid billing city';
				this.props.errorChange(payment_error);
				this.props.setCountryDetail({ valid_billing_city: true });
				return errMsg += 1;
			}
		}
		
		if (!guest.billing_address || guest.billing_address!=='') {
			var billing_address = guest.billing_address;
			if (billing_address.length >= 1 && billing_address.length <= 100) {
				if (!this.checkNameFormat(billing_address, "address")) {
					this.props.setCountryDetail({ valid_billing_address: true });
					payment_error.billing_address = 'Address must be contain A-Z, a-z, 0-9, space only';
					this.props.errorChange(payment_error);
					return errMsg += 1;
				}
				else {
					this.props.setCountryDetail({ valid_billing_address: false });
				}
			} else {
				this.props.setCountryDetail({ valid_billing_address: true });
				payment_error.billing_address = 'Address must be contain A-Z, a-z, 0-9, space only';
				this.props.errorChange(payment_error);
				return errMsg += 1;
			}
		}
		if (!guest.state || guest.state!=='') {
			var state = guest.state;
			if (state !== "") {
				if (!this.checkNameFormat(state, "check")) {
					payment_error.state = 'Only English letters and spaces are allowed';
					this.props.errorChange(payment_error);
					this.props.setCountryDetail({ valid_state: true });
					return errMsg += 1;
				}
				else {
					this.props.setCountryDetail({ valid_state: false });
				}
			} else {
				payment_error.state = 'Enter valid billing state';
				this.props.errorChange(payment_error);
				this.props.setCountryDetail({ valid_state: true });
				return errMsg += 1;
			}
		}
		if (!guest.postal_code || guest.postal_code!=='') {
			var postal_code = guest.postal_code;
			var postalRawValue = postal_code;
			if (postal_code === "") {
				payment_error.postal_code = 'Enter valid billing postal code';
				this.props.errorChange(payment_error);    
				this.props.setCountryDetail({ valid_postal_code: true });
				return errMsg += 1;
			} 
			
			else if (this.props.state.selectedCountry.value === "USA") {
				if (postalRawValue.length === 10) {
					const code = postalRawValue.slice(0, 5) + "-" + postalRawValue.slice(-4)
					this.props.setCountryDetail({ postal_code: code }).then(() => {
						var regEx = /^\d{5}-\d{4}$/;
						if (!regEx.test(this.props.state.postal_code)) {
							this.props.setCountryDetail({ valid_postal_code: true });
							payment_error.postal_code = 'Postal Code must be contain A-Z, a-z, 0-9, space only';
							this.props.errorChange(payment_error);
							return errMsg += 1;
						}
						else {
							this.props.setCountryDetail({ valid_postal_code: false });
							this.props.setCountryDetail(postal_code);
						}
					})
				}
				else {
					this.props.setCountryDetail({ valid_postal_code: true });
					payment_error.postal_code = 'Enter valid billing postal code';
					this.props.errorChange(payment_error);
					return errMsg += 1;
				}
			}
			else if (this.props.state.selectedCountry.value === "CAN") {
				if (postalRawValue.length === 7) {
					const code = postalRawValue.slice(0, 3) + " " + postalRawValue.slice(-3)
					var regEx = /[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/;
					if (!regEx.test(code)) {
						this.props.setCountryDetail({ valid_postal_code: true });
						payment_error.postal_code = 'Postal code must be like Eg. A1B 2C3';
						this.props.errorChange(payment_error);
						return errMsg += 1;
					}
					else {
						this.props.setCountryDetail({ valid_postal_code: false });
						this.props.setCountryDetail(postal_code);
					}
				}
				else {
					this.props.setCountryDetail({ valid_postal_code: true });
					payment_error.postal_code = 'Enter valid billing postal code';
					this.props.errorChange(payment_error);
					return errMsg += 1;
				}
			} else{
				if (!this.checkNameFormat(postal_code, "postal_code")) {
					payment_error.postal_code = 'Postal Code must be contain A-Z, a-z, 0-9, space only';
					this.props.errorChange(payment_error);
					this.props.setCountryDetail({ valid_postal_code: true });
					return errMsg += 1;
				}
				else {
					this.props.setCountryDetail({ valid_postal_code: false });
				} 
			}
		}
	
		if (errMsg === 0) {
			if(this.props.state?.click2pay){
				this.setState({ loaderRun: true }, () => {				
					this.getCardsHandler();					
				});
			} else {			
				this.proceedToPaymentScreen();
			}
		}
	}
	proceedToPaymentScreen(){
		this.props.setCountryDetail(this.props.state.current_country).then(() => {
			var initiate_trans = {
				loderRun: false,
				validCardName: false,
				paymentframe:false,
				address_section:true
			}
			this.props.setCountryDetail(initiate_trans);
			if (this?.props?.state?.app_details?.payment_modes?.apple_pay && window.ApplePaySession && window.ApplePaySession.canMakePayments) {
				var initiate_trans = {
					loderRun: false,
					showCard: "apple_card_screen",
					validCardName: false,
					paymentframe:true
				}
				this.props.setCountryDetail(initiate_trans);
			}
			else if(this?.props?.state?.app_details?.payment_modes?.samsung_pay){
				var initiate_trans = {
					loderRun: false,
					showCard: "samsung_pay_screen",
					validCardName: false,
					paymentframe:true
				}
				this.props.setCountryDetail(initiate_trans);
			}
			else {
				var initiate_trans = {
					loderRun: false,
					showCard: "card_screen",
					validCardName: false,
					paymentframe:true
				}
				this.props.setCountryDetail(initiate_trans);
			}
		})
	}
	handleGuestEvent(name) {
		var error = {};
		error[name] = '';
		this.props.errorChange(error);
	}
	handleKeyEvent(e) {
		var theEvent = e || window.event;
		if (theEvent.type === 'paste') {
			key = e.clipboardData.getData('text/plain');
		} else {
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
		}
		var regex = /[0-9]|\./;
		if (!regex.test(key)) {
			theEvent.returnValue = false;
			if (theEvent.preventDefault) theEvent.preventDefault();
		}
	}
	handlePaste(e) {
		e.preventDefault();
	}
	fraudCheck(){
		var self = this;
		window.seon.config({
			session_id: `"${self.props.match.params.id}"`,
			audio_fingerprint: true,
			canvas_fingerprint: true,
			webgl_fingerprint: true,
			onSuccess: function(message) {
				console.log("success", message);
				window.seon.getBase64Session(function(data) {
					if (data) {
						console.log("Session payload", data);
						self.setState({ payload : data })
					} else {
						console.log("Failed to retrieve session data.");
					}
				});
			},
			onError: function(message) {
				
			}
		});
	}
	learnmore(){
		var self = this;
		self.setState({learnmore:true}, () => {
			const learnmoreSection = document.querySelector('src-learn-more');
			learnmoreSection.addEventListener('close', event => {
				//document.getElementById("error_log")innerHTML += "<pre>Learn more closed : "+JSON.stringify(event)+"</pre>";
				self.setState({learnmore:false});
			});
		});
	}
	//Click2Pay
	async initializeClick2Pay(id) {
		var _this = this;
		const initParams = {
			"srcDpaId": id,
			"cardBrands": [
				"mastercard",
				"visa",
				"amex"
			],
			"dpaTransactionOptions": {
				"dpaLocale": "en_US",
				"paymentOptions":[{
					"dynamicDataType":"NONE"
				}],
				"customInputData":{
					"com.mastercard.dcfExperience":"PAYMENT_SETTINGS"
				},
				//"consumerNameRequested": true,
				"consumerEmailAddressRequested": true,
				//"consumerPhoneNumberRequested": true,
			},
			"dpaData": {
				"dpaPresentationName": "Foloosi",
				"dpaName": "Foloosi Technologies LTD"
			}
		}
		try {
			const result = await click2payInstance.init(initParams);
			console.log("result111",result);				
			//document.getElementById("error_log")innerHTML += "<pre>Init Success : "+JSON.stringify(result)+"</pre>";
			//_this.loadInitCall();
		} catch (error) {
			console.log('error111', error)
			//document.getElementById("error_log")innerHTML += "<pre>Init Error : "+JSON.stringify(error)+"</pre>";
			//_this.loadInitCall();
		}
	}
	async getCardsHandler () {   
		var self = this;
		try {
			const promiseResolvedPayload = await click2payInstance.getCards();
			console.log('CARDS', promiseResolvedPayload);
			//document.getElementById("error_log")innerHTML += "<pre>Get Cards Success : "+JSON.stringify(promiseResolvedPayload)+"</pre>";
			//self.props.payment_enable();
			self.setState({ loaderRun: true }, () => {
				
				if(promiseResolvedPayload.length >= 1){
					self.props.setCountryDetail({ loaderRun: true,showCard:"click2pay_screen" }).then(() => {
						self.props.setCountryDetail({click2paySavedCardList:promiseResolvedPayload}).then(() => {
							self.props.setCountryDetail({loaderRun:false,click2paySavedCardListShow:true});
						});
					});
					
				} else{
					this.idLookupHandler();
				}
			});
		} catch (promiseRejectedPayload) {
			console.log("error", promiseRejectedPayload);
			//document.getElementById("error_log")innerHTML += "<pre>Get Cards Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
		}
	}
	async idLookupHandler () {
		var self = this;
		var customerData = {
			email: self.props.state.email
		}
		try {
			console.log("customerData",customerData)
			const promiseResolvedPayload = await click2payInstance.idLookup(customerData);
			console.log("success",promiseResolvedPayload);
			//document.getElementById("error_log")innerHTML += "<pre>ID Lookup Data : "+JSON.stringify(customerData)+"</pre>";
			//document.getElementById("error_log")innerHTML += "<pre>ID Lookup Success : "+JSON.stringify(promiseResolvedPayload)+"</pre>";
			if(promiseResolvedPayload.consumerPresent){
				self.initiateValidationHandler();
			} else {
				self.proceedToPaymentScreen();
			}
		} catch (promiseRejectedPayload) {
			console.log("error", promiseRejectedPayload);
			//document.getElementById("error_log")innerHTML += "<pre>ID Lookup Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
		}
	}
	showOTPChannelScreen(){
		var self = this;
		const srcOtpChannelSelection = document.querySelector('src-otp-channel-selection');
		srcOtpChannelSelection.identityValidationChannels = self.state.click2payOtpList;
		srcOtpChannelSelection.addEventListener('continue', event => {
			//document.getElementById("error_log")innerHTML += "<pre>Continue Channel : "+JSON.stringify(event)+"</pre>";
			self.props.setCountryDetail({paymentframe:true, showCard: "click2payOtpListShow"}).then(() => {
				self.initiateValidationHandler();
				self.showOTPScreen();
			});
			
		});
		srcOtpChannelSelection.addEventListener('selectChannel', event => {
			console.log('CHANNEL SELECTED: ', event.detail);
			//document.getElementById("error_log")innerHTML += "<pre>Select Channel : "+JSON.stringify(event)+"</pre>";
			if(event.detail.identityType.toLowerCase() == 'guest'){
				self.props.setCountryDetail({showCard: "card_screen", loaderRun: false}).then(() => {
					self.proceedToPaymentScreen();
				});
			} else{
				self.setState({maskedValidationChannel:event.detail.maskedValidationChannel, requestedValidationChannelId:event.detail.validationChannelId})
			}
		});
	}
	cardListControl(){
		var self = this;
		const srcCardListSelection = document.querySelector('src-card-list');
		srcCardListSelection.loadCards(self.state.click2paySavedCardList);
		srcCardListSelection.addEventListener('clickAddCardLink', event => { 
			console.log('Add a Card button clicked: ', event);
			//document.getElementById("error_log")innerHTML += "<pre>Add Card Click : "+JSON.stringify(event)+"</pre>";
			self.props.setCountryDetail({ showCard:"card_screen",addNewCard: true, Error_Message: "",validCardName: true })
		});
		srcCardListSelection.addEventListener('clickSignOutLink', event => { 
			console.log('Not your cards button clicked: ', event);
			//document.getElementById("error_log")innerHTML += "<pre>Not your Cards Clicked : "+JSON.stringify(event)+"</pre>";
			self.proceedToPaymentScreen();
		});
		srcCardListSelection.addEventListener('selectSrcDigitalCardId', event => {
			console.log('A card was clicked: ', event.detail);
			//document.getElementById("error_log")innerHTML += "<pre>Card Clicked : "+JSON.stringify(event)+"</pre>";
			self.payWithReturnUserCard({srcDigitalCardId:event.detail});
		});
	}
		
	showOTPScreen(){
		var self = this;
		document.querySelector('src-otp-input').addEventListener('alternateRequested', event => {
			console.log('ALTERNATE CHANNEL REQUESTED: ', event);
			//document.getElementById("error_log")innerHTML += "<pre>Channel Change : "+JSON.stringify(event)+"</pre>";
			self.props.setCountryDetail({showCard: "showC2POTPChannelScreen", loaderRun: false}).then(() => {
				self.showOTPChannelScreen();
			});
		});
		document.querySelector('src-otp-input').addEventListener('continue', event => {
			console.log('CONTINUE EVENT: ', event);
			//document.getElementById("error_log")innerHTML += "<pre>Continue with selected channel : "+JSON.stringify(event)+"</pre>";
			self.validateCard();
		});
		document.querySelector('src-otp-input').addEventListener('otpChanged', event => {
			console.log('OTP CHANGED: ', event);
			//document.getElementById("error_log")innerHTML += "<pre>OTP Changed : "+JSON.stringify(event)+"</pre>";
			self.setState({otp:event.detail});
		});
	}
	async initiateValidationHandler () {
		var self = this;
		try {
			let promiseResolvedPayload;
			//requestedValidationChannelId:'EMAIL' or 'SMS'
			if(self.state.requestedValidationChannelId){
				  promiseResolvedPayload = await click2payInstance.initiateValidation({requestedValidationChannelId:self.state.requestedValidationChannelId});
			}
			else{
				promiseResolvedPayload = await click2payInstance.initiateValidation();
			}
			//document.getElementById("error_log")innerHTML += "<pre>OTP Validation Success : "+promiseResolvedPayload+"</pre>";
			if(promiseResolvedPayload.supportedValidationChannels.length >= 1){
				//self.props.payment_enable();
				self.setState({ loaderRun: false},() => {
					console.log("promiseResolvedPayload",promiseResolvedPayload)
					self.setState({click2payOtpList:promiseResolvedPayload.supportedValidationChannels,maskedValidationChannel:promiseResolvedPayload.maskedValidationChannel}, () => {
						self.props.setCountryDetail({paymentframe:true,showCard: "click2payOtpListShow", loaderRun: false,click2payOtpListShow:true}).then(() => {
							self.showOTPScreen();
						});
					});
				})
			} else{
				this.proceedToPaymentScreen();
			}
		} catch (promiseRejectedPayload) { 
			this.proceedToPaymentScreen();
			//document.getElementById("error_log")innerHTML += "<pre>OTP Validation Error : "+promiseRejectedPayload+"</pre>";
		}
	}
	validateCard(){
		var self = this;
		//self.props.payment_enable();
		self.validateHandler(this.state.otp);
		/* self.setState({ loaderRun: true,showCard:"click2pay_screen" }, () => {
			self.validateHandler(this.state.otp);
		}); */
	}
	renderOTP(){
		return (<src-otp-input id="src-otp-input" error-reason={this.state.otpErrorReason} locale="en_US" card-brands="mastercard,visa,amex" display-cancel-option="false" masked-identity-value={this.state.maskedValidationChannel} network-id="Mastercard" type="" is-successful="false" display-header="true" auto-submit="false" disable-elements="false" hide-loader="false" ></src-otp-input>)
	}
	async validateHandler () {
		var self = this;
		try {
			const promiseResolvedPayload = await click2payInstance.validate({value:this.state.otp});
			//document.getElementById("error_log")innerHTML += "<pre>OTP Verify Success : "+JSON.stringify(promiseResolvedPayload)+"</pre>";
			if(promiseResolvedPayload.length>=1){
				/* if(promiseResolvedPayload.length == 1){
					this.setState({ loaderRun: true,showCard:"single_card_screen",panLastFour:promiseResolvedPayload.panLastFour});
				} else{
					this.setState({ loaderRun: true,showCard:"multiple_card_screen"});
				}
				this.setState({click2paySavedCardList:promiseResolvedPayload}, () => {
					this.setState({loaderRun: false,showCard:"multiple_card_screen" }, () => {
						this.cardListControl();
					});
				}); */
				self.props.setCountryDetail({ loaderRun: true,showCard:"click2pay_screen" }).then(() => {
					self.setState({click2paySavedCardList:promiseResolvedPayload}, () => {
						self.props.setCountryDetail({loaderRun: false });
					})
				});
			} else{
				this.proceedToPaymentScreen();
			}
		} catch (promiseRejectedPayload) {
			if(JSON.parse(JSON.stringify(promiseRejectedPayload)).reason){
				self.setState({otpErrorReason:JSON.parse(JSON.stringify(promiseRejectedPayload)).reason}, () => {
					self.props.setCountryDetail({paymentframe:true,showCard: "click2payOtpListShow", loaderRun: false,click2payOtpListShow:true}).then(() => {
						//document.getElementById("error_log")innerHTML += "<pre>OTP Verify Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
						/* this.setState({otpErrorReason:""}, () => {
							document.getElementById('src-otp-input-element').innerHTML = `<src-otp-input id="src-otp-input" error-reason="${this.state.otpErrorReason}" locale="en_US" card-brands="mastercard,visa,amex" display-cancel-option="false" masked-identity-value="${this.state.maskedValidationChannel}" network-id="Mastercard" type="" is-successful="false" display-header="true" auto-submit="false" disable-elements="false" hide-loader="false" ></src-otp-input>`;
						}, () => {
							this.showOTPScreen();
						}); */
						//this.showOTPScreen();
					});
				});
			} else {
				this.proceedToPaymentScreen();
			}
		}
	}
	handleCvvChange(e,p) {
		const nodeList = document.querySelectorAll(".credit-card-cvv-input");
		for (let i = 0; i < nodeList.length; i++) {
			if(i !== p){
				nodeList[i].value = "";
			} else{
				this.setState({ c2p_cvv: e.target.value, Error_Cvv_Message: "" });
			}
		}
	}
	onInputCvv(e){
		return e.target.value;// = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
	}
	async encryptCard () {
		var self = this;
		var selected_country_code = self.props.state.selected_country ? self.props.state.selected_country.country_code : '+971';
		this.props.setCountryDetail({ showCard: "click2payNewUserFrame",loaderRun: true});
		const [cc_first_name, cc_last_name] = self.props.state.name.split(' ');
		var cardData;
		if(self.props.state.creditCardType == 'visa'){
			cardData = {
				"primaryAccountNumber": self.props.state.cardNumber,
				"panExpirationMonth": self.props.state.cardExpiryMonth,
				"panExpirationYear": self.props.state.cardExpiryYear,
				"cardSecurityCode": self.props.state.cardCvv,
				"cardholderFirstName": cc_first_name,
				"cardholderLastName": cc_last_name !== '' && cc_last_name !== undefined ? cc_last_name : cc_first_name
			}
		} else {
			cardData = {
				"primaryAccountNumber": self.props.state.cardNumber,
				"panExpirationMonth": self.props.state.cardExpiryMonth,
				"panExpirationYear": self.props.state.cardExpiryYear,
				"cardSecurityCode": self.props.state.cardCvv,
				"cardholderFirstName": cc_first_name,
				"cardholderLastName": cc_last_name !== '' && cc_last_name !== undefined ? cc_last_name : cc_first_name,
				"billingAddress": {
					"name": self.props.state.name,
					"line1": self.props.state.billing_address,
					"line2": self.props.state.billing_address,
					"line3": self.props.state.billing_address,
					"city": self.props.state.city,
					"state": self.props.state.state,
					"zip": self.props.state.postal_code,
					"countryCode": self.props.state.selectedCountry.value
				}
			}
		}
		//var cardData = {"primaryAccountNumber": "4622943127011022", "panExpirationMonth": "12", "panExpirationYear": "22", "cardSecurityCode": "665", "cardholderFirstName": "muthu", "cardholderLastName": "d"}
		console.log("cardData1",cardData)
		try {	
			const encryptCard = await click2payInstance.encryptCard(cardData);
			console.log("encryptCard-muthu",encryptCard)
			encryptedCard = encryptCard.encryptedCard;
			cardBrand = encryptCard.cardBrand;   
			//var srcWindow = window.open('', '_blank', 'popup')
			//srcWindow.moveTo(500, 100);
			//srcWindow.resizeTo(550, 650);
			self.props.setCountryDetail({loaderRun: false});
			//var srcWindow = document.getElementById("clickToPayFrame").contentWindow;
			var checkoutIframe = document.getElementById("clickToPayFrame");
			var srcWindow = checkoutIframe.contentWindow ? checkoutIframe.contentWindow : checkoutIframe.contentDocument.defaultView;
			srcWindow.addEventListener('message', this.cancel);
			//window.childSrcWindow = srcWindow;
			////document.getElementById("error_log")innerHTML += "<pre>Card Data : "+JSON.stringify(cardData)+"</pre>";
			////document.getElementById("error_log")innerHTML += "<pre>Encypted Card Data : "+JSON.stringify(encryptCard)+"</pre>";
			checkoutWithNewCard = {
				"windowRef": srcWindow,
				"encryptedCard": encryptedCard,
				"dpaTransactionOptions": {
					"dpaLocale": "en_US",
					"customInputData":{
						"com.mastercard.dcfExperience":"PAYMENT_SETTINGS"
					}
				},
				"consumer": {
					"emailAddress": self.props.state.email,
					"mobileNumber": {
						"phoneNumber": self.props.state.ph_number,
						"countryCode": self.props.state?.selected_country?.country_code.replace(/[^a-zA-Z0-9 ]/g, '') || selected_country_code
					}
				},
				"cardBrand": cardBrand/* ,
				"consumer": {
					"emailAddress": self.props.state.email,
					"mobileNumber": {
						"phoneNumber": self.props.state.ph_number,
						"countryCode": self.props.state.selected_country.country_code
					}
				},
				"dpaTransactionOptions": {
					"dpaLocale": "en_US",
					"customInputData":{
						"com.mastercard.dcfExperience":"PAYMENT_SETTINGS"
					}
				} */
				}
				console.log("checkoutWithNewCard",checkoutWithNewCard);
				////document.getElementById("error_log")innerHTML += "<pre>Encrypt Card Error : "+JSON.stringify(error)+"</pre>";
				this.checkoutWithNewCardHandler();
			
			} catch (error) {
				console.log("error", error);
				self.paynow('unsaved_card');
			}
		//})
	}
	async checkoutWithNewCardHandler(){ 
		console.log("in",checkoutWithNewCard);
		var self = this;
		try {
			console.table("ssss",checkoutWithNewCard);
			////document.getElementById("error_log")innerHTML += "<pre>Checkout with New card check : "+JSON.stringify(checkoutWithNewCard)+"</pre>";
			//return false;
			const promiseResolvedPayload = await click2payInstance.checkoutWithNewCard(checkoutWithNewCard) 
			console.log("checkoutWithNewCard-success",JSON.stringify(promiseResolvedPayload));
			console.log("promiseResolvedPayload?.checkoutActionCode",promiseResolvedPayload?.checkoutActionCode);
			//document.getElementById("error_log")innerHTML += "<pre>Checkout with New Card Success : "+JSON.stringify(promiseResolvedPayload)+"</pre>";
			//window.childSrcWindow.close();
			// document.getElementById("clickToPayFrame").style.display = "none";
			if(promiseResolvedPayload.checkoutActionCode == "COMPLETE"){
				try {                       
					console.log(promiseResolvedPayload)
					self.setState({click2payToken:promiseResolvedPayload},() => {
						self.paynow('click2pay');
					})
				}
				catch(err) {
				
					console.log(err.message);
				}
			} else if(promiseResolvedPayload.checkoutActionCode == "CHANGE_CARD"){
				
				self.props.setCountryDetail({ loaderRun: true,showCard:"click2pay_screen" }).then(() => {
					
					self.props.setCountryDetail({loaderRun:false,click2paySavedCardListShow:true});
				});
			} else if(promiseResolvedPayload.checkoutActionCode == "SWITCH_CONSUMER"){
				
				self.idLookupHandler();
				
			} else if(promiseResolvedPayload?.checkoutActionCode == "CANCEL"){
				
				this.setState({disablec2p:false,}, () => {
					
					this.proceedToPaymentScreen();
					
					this.props.checkChange({ click2pay_agree_card: false})
				});
			
			} else if(promiseResolvedPayload.checkoutActionCode == "ERROR"){
				
				self.proceedToPaymentScreen();
				
			} else{
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				self.proceedToPaymentScreen();
			}
		} catch (promiseRejectedPayload) {	
			//console.log("checkoutWithNewCard-error",JSON.stringify(promiseRejectedPayload));
			//document.getElementById("error_log")innerHTML += "<pre>Checkout with New Card Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
			//window.childSrcWindow.close();
			//document.getElementById("clickToPayFrame").style.display = "none";
			if(promiseRejectedPayload.reason == "CARD_INVALID"){
				this.setState({disablec2p:false,}, () => {
					this.proceedToPaymentScreen();
					this.props.checkChange({ click2pay_agree_card: false})
				});
				self.props.setCountryDetail({loaderRun:false,click2paySavedCardListShow:true});
			} else if(promiseRejectedPayload.reason == "INVALID_PARAMETER"){
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				this.setState({disablec2p:false,}, () => {
					this.proceedToPaymentScreen();
					this.props.checkChange({ click2pay_agree_card: false})
				});
				self.proceedToPaymentScreen();
			} else{
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				self.proceedToPaymentScreen();
			}
		}
	}
	addNewCardFn(){
		this.props.setCountryDetail({ showCard:"card_screen"});
		this.setState({ showCard:"card_screen",addNewCardC2P: true, addNewCard: true, Error_Message: "",validCardName: true })
	}
	async notYourCards(){
		try {
			const promiseResolvedPayload = await click2payInstance.signOut();
			console.log("signout",JSON.stringify(promiseResolvedPayload));
			this.props.guest_screen_true();
		} catch (promiseRejectedPayload) {
			console.log("checkoutWithNewCard-error",JSON.stringify(promiseRejectedPayload));
			//document.getElementById("error_log")innerHTML += "<pre>Checkout with New Card Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
		}
	}
	payWithReturnUserCard(cardObj){
		var self = this;
		if(self.state.c2p_cvv !== ''){
			self.setState({ Error_Cvv_Message:""});
			//self.props.payment_enable();
			self.props.setCountryDetail({showCard:"click2payReturnUserFrame",clickToPayFrameReturnUser:true,loaderRun: true}).then(() => {
				self.checkoutWithReturnUserHandler(cardObj);
			});
		} else{
			self.setState({ Error_Cvv_Message:"Please Enter CVV"});
		}
	}
	async checkoutWithReturnUserHandler(cardObj){
		var self = this;
		/* var srcWindow = window.open('', '_blank', 'popup')
		srcWindow.moveTo(500, 100);
		srcWindow.resizeTo(550, 650);
		srcWindow.addEventListener('message', this.cancel);
		window.childSrcWindow = srcWindow;    */    
		console.log("cardobj",cardObj);
		//document.getElementById("error_log")innerHTML += "<pre>Muthu Check Card Data : "+JSON.stringify(cardObj)+"</pre>";
		var checkoutIframe = document.getElementById("clickToPayFrameReturnUser");
		var srcWindow = checkoutIframe.contentWindow ? checkoutIframe.contentWindow : checkoutIframe.contentDocument.defaultView;
		//var srcWindow = document.getElementById("clickToPayFrame").contentWindow;
		srcWindow.addEventListener('message', this.cancel);
		//srcWindow.addEventListener('message', this.cancel);
		try {
			var checkoutWithReturnUserCard = {
				"srcDigitalCardId" : cardObj.srcDigitalCardId,
				"windowRef": srcWindow,
				"dpaTransactionOptions": {
					"dpaLocale": "en_US",
					"customInputData":{
						"com.mastercard.dcfExperience":"PAYMENT_SETTINGS"
					}
				}
			};
			console.log("checkoutWithReturnUserCard-Info",checkoutWithReturnUserCard);
			//document.getElementById("error_log")innerHTML += "<pre>Return User Card Data : "+checkoutWithReturnUserCard+"</pre>";
			const promiseResolvedPayload = await click2payInstance.checkoutWithCard(checkoutWithReturnUserCard)
			//document.getElementById("error_log")innerHTML += "<pre>Return User with Card Success : "+JSON.stringify(promiseResolvedPayload)+"</pre>";
			console.log("checkoutWithReturnUserCard-success",JSON.stringify(promiseResolvedPayload));
			//window.childSrcWindow.close();
			document.getElementById("clickToPayFrameReturnUser").style.display = "none";
			if(promiseResolvedPayload.checkoutActionCode == "COMPLETE"){
				try {                            
					console.log(promiseResolvedPayload)
					self.setState({click2payToken:promiseResolvedPayload},() => {
						self.paynow('click2pay');
					})
				}
				catch(err) {
					console.log(err.message);
				}
			} else if(promiseResolvedPayload.checkoutActionCode == "CHANGE_CARD"){
				self.props.setCountryDetail({ loaderRun: true,showCard:"click2pay_screen"}).then(() => {
					self.props.setCountryDetail({loaderRun:false,click2paySavedCardListShow:true});
				});
			} else if(promiseResolvedPayload.checkoutActionCode == "SWITCH_CONSUMER"){
				self.idLookupHandler();
			} else if(promiseResolvedPayload.checkoutActionCode == "CANCEL"){
				self.proceedToPaymentScreen();
			} else if(promiseResolvedPayload.checkoutActionCode == "ERROR"){
				self.proceedToPaymentScreen();
			} else{
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				self.proceedToPaymentScreen();
			}
		} catch (promiseRejectedPayload) {
			console.log("checkoutWithReturnUserCard-error",JSON.stringify(promiseRejectedPayload));
			//document.getElementById("error_log")innerHTML += "<pre>Return User with Card Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
			//document.getElementById("clickToPayFrame").style.display = "none";
			if(promiseRejectedPayload.reason == "CARD_INVALID"){
				self.props.setCountryDetail({loaderRun:false,click2paySavedCardListShow:true});
			} else if(promiseRejectedPayload.reason == "INVALID_PARAMETER"){
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				self.proceedToPaymentScreen();
			} else{
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				self.proceedToPaymentScreen();
			}
		}
	}
	backtoSavedCard(){
		this.setState({ addNewCard: false, Error_Message: ""});
		this.props.setCountryDetail({showCard:"click2pay_screen" });
	}

	loadTutelarSdk(){
		var self = this;
		let tutelarRef = new window.Tutelar({ public_key: "tutpk_live_fySLEqoxgW7F" });
		tutelarRef.then((resp) => {
			self.setState({tutelar_payload_state:resp.getClientDetails()})
		}).catch((err) => {
			console.log(err);
			self.setState({tutelar_payload_state:err})
		});
	}
	componentDidMount () {
		this.fraudCheck();
		this.loadInitCall();
		this.loadTutelarSdk();
	}

	loadInitCall = async() =>{
		await dataservice.returnCurrentCountry().then((response) => {
			this.setState({currentIp:response.query});
			this.props.currentcountry(response).then(() => {
				var country = returnCodeInfo(this.props.state.current_country.countryCode);
				this.props.setCountryDetail({ shippingSelectedCountry: { label: country.name, value: country["3digit"], code:country.code },selectedCountry: { label: country.name, value: country["3digit"], code:country.code } }).then(() => {
					if (this.props.state.postal_code !== undefined && this.props.state.postal_code !== "") {
						if (this.props.state.selectedCountry.value === "CAN" || this.props.state.selectedCountry.value === "USA") {
							this.setValidPostal(this.props.state.postal_code);
						}
						else {
							this.setValidFields("postal_code");
						}
					}
					if (this.props.state.state !== undefined && this.props.state.state !== "") {
						if (this.props.state.selectedCountry.value === "CAN" || this.props.state.selectedCountry.value === "USA") {
							var StateNames = []
							StateList.map(function (statename, i) {
								if (this.props.state.selectedCountry.label === statename.name) {
									return (
										StateNames = statename.states.map(function (list, j) {
											return (
												{ label: list.name, value: list.abbreviation }
											)
										}, this)
									)
								}
							}, this);
							StateNames.map(function (statename, i) {
								if (statename.name === this.props.state.state) {
									this.props.setCountryDetail({ valid_state: false, selectedState: { label: statename.name, value: statename.abbreviation } });
								}
								else {
									this.props.setCountryDetail({ valid_state: true });
								}
							}, this);
						}
						else {
							this.setValidFields("state");
						}
					}
				})
				
			 dataservice.getValues(GBLVAR.API_PRODUCT,this.state.data.reference_id,{})
				.then((response) => {
					if (response.status===404) {
			         this.setState({availableStatus:response})
					}
					else{
						this.setState({available:response,dataList:response.data, productValue:response?.data?.product_quantity
							,orderSuccessTotal:response?.data?.grand_total,status:response?.data?.status,
							trans_amount:response?.data?.grand_total}, () => {
							if(response.code == '1001'){
								this.props.history.push(GBLVAR.PAGE_NAME.LinkPaid);
							}
							 else{
								this.props.getDetails(response).then(() => {
									window.app_details = this.props.state.app_details;
									window.orderTotal = this.state.orderSuccessTotal;
									this.setState({homeState:false});
									this.props.setCountryDetail({ viewscreen: "Home" }).then(() => {
										const getdetails = this.props.state.app_details;
										if (getdetails?.user?.payment_gateway === "ngenius") {
											document.documentElement.style.setProperty('--main-theme-color', getdetails?.user?.theme_color);
											document.documentElement.style.setProperty('--main-primary-color', getdetails?.user?.primary_color);
										}
										console.log("click2payEnable && this.props.state?.click2pay && this.props.state?.dpa_id",click2payEnable && this.props.state?.click2pay && this.props.state?.dpa_id)
										if(click2payEnable && this.props.state?.click2pay && this.props.state?.dpa_id){
											this.initializeClick2Pay(this.props.state?.dpa_id); 
										}
										
									})
								})
							}
						})
					}
					}) 
			

			})
		})
		
	}
	
	handleActiveChange = () => {
		this.setState({ active: true });
	}
	handleClose = () => {
		this.setState({ active: false });
	}
	qtyChange=(type,productId)=>{
		var self = this;
		var productQuantity = self.state.count;
		var availableStock = self.state.productValue;
		if (type == 'increase' ) {
			self.setState({qtyMinus:true});
			productQuantity = parseInt(productQuantity) + 1;
			self.setState({count:productQuantity})
			if (availableStock != 'undefined' && availableStock > 0 && productQuantity > availableStock) {
				toast.error("Limited quantity only available")
				self.setState({qtyPlus:false});
				productQuantity = parseInt(productQuantity) - 1;
				self.setState({count:productQuantity})
			}
		} else {
			
			self.setState({qtyPlus:true});
			if (productQuantity > 1){
				self.setState({qtyMinus:true});
				productQuantity = parseInt(productQuantity) - 1;
				self.setState({count:productQuantity})
			}
			else{
				self.setState({qtyMinus:false});
			}
		}
		var data = {'product_id' : productId,'quantity' : productQuantity}
		dataservice.postValues(GBLVAR.API_LIST.QtyChange, '', data, {})
		.then((response)=>{
			let order = response?.data?.order_taxes;
			var result = response.data;
			if (result ) {
				self.setState({amount:result.orderTotal,count:result.total_quantity,
					shipping_amount:result.shipping_amount,
					vat_amount:result.vat_amount,order_tax:order,trans_amount:result.orderTotal});	
						window.amount=this.state.amount		
		}
			else {
				self.setState({qtyMinus:true});
				productQuantity = parseInt(productQuantity) - 1;
				self.setState({count:productQuantity})
				
				
				
			}			
		})
		;
	}
	render() {
		const selected_country_flag = this.props.state.selected_country ? this.props.state.selected_country.country_flag : 'ae';
		const selected_country_code = this.props.state.selected_country ? this.props.state.selected_country.country_code : '+971';
		const getdetails = this.props.state.app_details;
		const customStyles = {
			control: () => ({
				border: '0', minHeight: 'auto', boxShadow: 'none', float: 'left', width: '100%'
			}),
			indicatorsContainer: () => ({
				padding: '0', float: 'left', width: '30px'
			}),
			valueContainer: () => ({
				padding: '0 10px 0 0', float: 'left', width: 'calc(100% - 30px)', position: 'relative'
			}),
			indicatorSeparator: () => ({
				marginRight: '2px', backgroundColor: '#e1e1e1', width: '1px', float: 'left', height: '12px', marginTop: '5px'
			}),
			dropdownIndicator: () => ({
				padding: '0', float: 'left', width: '25px', height: '18px'
			}),
			input: () => ({
				margin: '0px', padding: '0px'
			})
		}
		const countryList = Countries.map(function (country, i) {
			return (
				{ label: country.name, value: country["3digit"], code: country.code }
			)
		}, this);
		var StateNames = [];
		StateList.map(function (statename, i) {
			if (this.props.state.selectedCountry.label === statename.name) {
				return (
					StateNames = statename.states.map(function (list, j) {
						return (
							{ label: list.name, value: list.abbreviation }
						)
					}, this)
				)
			}
		}, this);		
		return (
			<>
			 <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
			
			<div className="fpl_body">
				<div className="fpl_body_center">
					<MobileTopHeader reference_id={this.state.data.reference_id} />
			{this.state.available?.message ==="Product detail are" ? <div className='row'>
					
					<><div className='col-xs-12 col-sm-7 col-md-7'>
					<Header handler={this.handler} ref_id={this.state.data.reference_id} ord_id={this.state.order_id} payable={this.state.amount}/>
					</div>
					<div className='col-xs-12 col-sm-5 col-md-5'>
						<div className="fpl_merchant_right">
						<div className="fpl_merchant_rightTop">
							{(this.props.state.paymentframe === true) ?(
							<div className="relative">
								<div className="fpl_merch_detailName">{this.props.state.name}</div>
								<div className="fpl_mob_wrap">
									<div className="fpl_merch_detailMob">{this.props.state.ph_number}</div>
									<span>|</span>
									<div className="fpl_merch_detailEmail">{this.props.state.email}</div>
								</div>
							</div>
							):(
							<div className="fpl_merch_detail">Your Details</div>
							)}
						</div>
						{this.state.status?<div className="fpl_merchant_rightMid">
							{
							this.props.state.viewscreen == "Home" ?
							<>
							{
								this.props.state.paymentframe === true
								?
								<>
								{this.props.state.showCard === "click2payNewUserFrame" ?
										<div className="add_new_wrap">
											
											<a onClick={e => this.proceedToPaymentScreen()}>Pay with onther way</a>
											
										</div> : null
									}
								<div className="frame-open foloosiPluginApiiframeWrapper" >
									
									{(this.props.state.loderRun && this.state.homeState && this.state.headerState) &&
										<Loader className="loaderIcon loaderIconWrap" image="loader.gif" />
									}
									{(this.props.state.loderRun) &&
										<Loader className="loaderIcon loaderIconWrap" image="loader.gif" />
									}
									{
										this.props.state.showCard === "samsung_pay_screen" ?(
										<div className="paymode_section">
											<div className="paymode_section_btn_warp">
												<a className="paymode_section_credit" onClick={e => this.props.setCountryDetail({ showCard: "card_screen" })}>Pay with Card</a>
											</div>
											<div className="paymode_section_split">OR</div>
											<div className="paymode_section_btn_warp">
												<a onClick={e => this.samsungPay()}>
													<img src={imagePath('./samsung.png')} alt="" />
												</a>
											</div>
										</div>
		):
										this.props.state.showCard === "card_screen" ?
											<>
											{(this.state.addNewCardC2P) ?
												<div className="add_new_wrap">
													{(this.state.click2paySavedCardList.length>=1) &&
													<a onClick={e => this.backtoSavedCard()}>Back to Saved Cards</a>
													}
												</div> : null
											}
										<div className="width_100" onKeyPress={e => this.props.setCountryDetail({ Error_Message: "" })}>
										{
											this.props.state.Error_Message &&
											<div className="error_message_top">{this.props.state.Error_Message}</div>
										}
										<div className="fpl_detail">
											<label>Card Holder Name</label>
											<input
												type="text"
												name="name"
												placeholder="Card Holder's Name"
												value={this.props.state.name}
												onChange={e => { this.handleChange(e); }}
												style={{ borderColor: this.props.state.validCardName ? 'red' : '#e1e1e1' }}
												ref="nameinput"
												tabIndex="5"
											/>
											{this.props.state.payment_error.name &&
												<span
													className="error_message_bottom">
													{this.props.state.payment_error.name}
												</span>}
										</div>
										<div className="fpl_detail">
											<div className="fpl_detail_left">
												<label>Card Number</label>
												<Cleave type="tel" style={{ borderColor: this.props.state.cardNumberValid ? 'red' : '#e1e1e1' }}
													value={cardNumber} placeholder="Enter card number" options={{ creditCard: true, onCreditCardTypeChanged: this.onCreditCardTypeChanged }}
													onChange={this.onCreditCardChange} tabIndex="1" />
												<div className="pay_pop_card_img" style={{ backgroundImage: `url(${this.props.state.creditCardType == 'visa' && imagePath("./visa.png") || this.props.state.creditCardType == 'rupay' && imagePath("./rupay.png") || this.props.state.creditCardType == 'master' || this.props.state.creditCardType == 'mastercard' && imagePath("./master.png") || this.props.state.creditCardType == 'diners' && imagePath("./diners.png") || this.props.state.creditCardType == 'amex' && imagePath("./amex.png") || this.props.state.creditCardType == 'discover' && imagePath("./discover.png")})` }}></div>
											</div>
											<div className="fpl_detail_mid">
												<label>Expiry</label>
												<Cleave tabIndex="2" placeholder="MM/YY" options={{ date: true, datePattern: ['m', 'y'] }}
													onKeyUp={e => { this.handleExpiryMonthYear(e); }} ref="monthyearinput"
													onChange={this.onDateChange} style={{ borderColor: this.props.state.expiry_common ? 'red' : '#e1e1e1' }} />
											</div>
											<div className="fpl_detail_right">
												<label>CVV</label>
												{this.props.state.creditCardType == 'amex' ?
													<Cleave style={{ borderColor: this.props.state.cardCvvValid ? 'red' : '#e1e1e1' }} type="password" placeholder="••••" maxLength="4" options={{ numericOnly: true }}
														onChange={this.handleOnCvvChange} ref="cvvinput" name="cvvinput" tabIndex="4" />
													:
													<Cleave style={{ borderColor: this.props.state.cardCvvValid ? 'red' : '#e1e1e1' }} type="password" placeholder="•••" maxLength="3" options={{ numericOnly: true }}
														onChange={this.handleOnCvvChange} ref="cvvinput" name="cvvinput" tabIndex="4" />
												}
											</div>
										</div>
										{this.props.state?.click2pay && 
										this.props.state.click2payOtpListShow == true ? null:
										<>
										{this.props.state?.click2pay && this.state.disablec2p &&
										<>
										<div className="fpl_detail m-t-10">
											<img className="pull-left" src={imagePath('./click-to-pay.svg')} alt="" />
										</div>
										
										{/* <div className="fpl_detail m-t-10">
											<input className="hide checkmark_cust" id="click2pay_agree_card" name="click2pay_agree_card" type="checkbox" checked={this.props.state.click2pay_agree_card}
												onChange={event => this.props.checkChange({ click2pay_agree_card: !this.props.state.click2pay_agree_card })} />
												<label htmlFor="click2pay_agree_card" className="custom_check_box">I agree to share my card details, billing address and email with {this.state.creditCardType == 'visa' && "Visa" || this.state.creditCardType == 'rupay' && "Rupay" || this.state.creditCardType == 'master' || this.state.creditCardType == 'mastercard' && "Mastercard" || this.state.creditCardType == 'diners' && "Discover" || this.state.creditCardType == 'amex' && "Amex" || this.state.creditCardType == '' && "Mastercard"} to allow me to securely enroll in
												Click to Pay for faster checkouts.</label><a className="learnmore_txt" onClick={() => this.learnmore()}>Learn More</a>
										</div> */}
										<div className="fpl_detail m-t-10">
											
											<input className="hide checkmark_cust" id="click2pay_agree_card" name="click2pay_agree_card" type="checkbox" checked={this.props.state.click2pay_agree_card}
																onChange={event => this.props.checkChange({ click2pay_agree_card: !this.props.state.click2pay_agree_card })} />
        											<span class="width_100"><label htmlFor="click2pay_agree_card" className="custom_check_box">I agree to share my card details, billing address and email with {this.state.creditCardType == 'visa' && "Visa" || this.state.creditCardType == 'rupay' && "Rupay" || this.state.creditCardType == 'master' || this.state.creditCardType == 'mastercard' && "Mastercard" || this.state.creditCardType == 'diners' && "Discover" || this.state.creditCardType == 'amex' && "Amex" || this.state.creditCardType == '' && "Mastercard"} to allow me to securely enroll in
       												 Click to Pay for faster checkouts.</label><a className="learnmore_txt" onClick={() => this.learnmore()}>Learn More</a>
    													</span>
										</div>
										{this.state.learnmore &&
											<src-learn-more id="src-learn-more" display-close-button="true" display-ok-button="true" locale="en_US" card-brands="mastercard,visa,amex" ></src-learn-more>
										}
										</>
										}
										</>
										}
										{this.props.state?.click2pay &&
										this.state.addNewCardC2P &&
										<>
										{	this.props.state['click2pay'] && this.state.disablec2p &&
										<>
										<div className="fpl_detail m-t-10">
											<img className="pull-left" src={imagePath('./click-to-pay.svg')} alt="" />
										</div>
										<div className="fpl_detail m-t-10">
											<input className="hide checkmark_cust" id="click2pay_agree_card" name="click2pay_agree_card" type="checkbox" checked={this.props.state.click2pay_agree_card}
												onChange={event => this.props.checkChange({ click2pay_agree_card: !this.props.state.click2pay_agree_card })} />
											<span class="width_100"><label htmlFor="click2pay_agree_card" className="custom_check_box">Yes, I want to add this card to Click to Pay. Foloosi will share my card details with {this.state.creditCardType == 'visa' && "Visa" || this.state.creditCardType == 'rupay' && "Rupay" || this.state.creditCardType == 'master' || this.state.creditCardType == 'mastercard' && "Mastercard" || this.state.creditCardType == 'diners' && "Discover" || this.state.creditCardType == 'amex' && "Amex" || this.state.creditCardType == '' && "Mastercard"}.</label>
										</span>	</div>
										</>}
										</>
										}
										<div className="fpl_detail_btn">
											{this.props.state.validCardName === false && this.props.state.cardNumberValid === false && this.props.state.expiry_common === false && this.props.state.cardCvvValid === false ?
												this.props.state.click2pay_agree_card ?
													<a className="pay_popup_submit" onClick={e => this.encryptCard()} >Pay Now</a>
													:
													<a className="pay_popup_submit" onClick={e => this.paynow('card')} >Pay Now</a>
												:
												<a className="pay_popup_submit_disable" onClick={e => this.showErrors()}>Pay Now</a>
											}
										</div>
									</div>
									</>: 
									this.props.state.showCard === "click2payNewUserFrame" ?
									<div className="fpl_detail_btn c2pay_card">
										<iframe id="clickToPayFrame" className="clickToPayFrame"></iframe>
									</div>
									:
									this.props.state.showCard === "click2payReturnUserFrame" ?
									<>
									<div className="fpl_detail_btn c2pay_card">
										<iframe id="clickToPayFrameReturnUser" className="clickToPayFrame"></iframe>
									</div>
									</>
									:
									this.props.state.showCard === "click2payOtpListShow" ?
									<>
									<div className="col-xs-12 p-0" id="src-otp-input-element">
									{this.renderOTP()}
									<div className='pay_ontherway'>
										<a className='pay_resend' onClick={e => this.proceedToPaymentScreen(e)}>Pay with onther way</a>
										</div>
									</div>
									</>
									:
									this.props.state.showCard === "showC2POTPChannelScreen" ?
									<src-otp-channel-selection id="src-otp-channel-selection" locale="en_US" card-brands="mastercard,visa,amex" display-cancel-option="false" type="" display-header="true"></src-otp-channel-selection>
									:
									this.props.state.showCard === "single_card_screen" ?
									<>
										<src-card card-art="" descriptor-name="Citi" account-number-suffix="1234" card-status="VALID" feature-text="This card participates in Citi pay with points" locale="en_US"></src-card>
									</>
									:
									this.props.state.showCard === "multiple_card_screen" ?
									<>
									<src-card-list id="srcCardList" locale="en_US" card-brands="mastercard,visa,amex" display-cancel-option="false" display-add-card="true" display-preferred-card="true" display-sign-out="true" card-selection-type="" src-digital-card-id="" display-header="true" background="transparent" ></src-card-list>
									</>
									:
									this.props.state.showCard === "click2pay_screen" ?
									<>
									{this.state.click2paySavedCardList.length >= 1 &&
										<div className="add_new_wrap">
											<div className="pull-left">
												<img className="pull-left" src={imagePath('./c2p.svg')} alt="" />
												<img className="pull-left" src={imagePath('./pipe.svg')} alt="" />
												<img className="pull-left" src={imagePath('./mastercard.svg')} alt="" />
												<img className="pull-left" src={imagePath('./visa.svg')} alt="" />
												<img className="pull-left" src={imagePath('./amex.svg')} alt="" />
											</div>
											
											<a className="btn btn-default" onClick={e => this.addNewCardFn()}>Add New Card</a>
											<div className="clearfix"></div>
											<a className="not_your_cards" onClick={() => this.notYourCards()}>Not Your Cards?</a>
										</div>
										}
										{
											this.state.Error_Cvv_Message &&
											<div className="error_message_top">{this.state.Error_Cvv_Message}</div>
										}
										{this.state.click2paySavedCardList.map((card_list, p) => {
											return (
												<div className="credit-card visa selectable" key={p}>
													<input id={"credit_label_wrap"+p} className="credit_label_wrap_input" type="radio" name="credit_label_wrap"/>
													<label className="credit_label_wrap" htmlFor={"credit_label_wrap"+p}>
														<div className="credit-card-name">{card_list.digitalCardData.descriptorName} <img className="card_img" src={card_list.digitalCardData.artUri}/></div>
														<div className="credit-card-last4">
															<span className="card_no_star">**** **** ****</span> {card_list.panLastFour}
															
														</div>
														<div className="credit_label_wrap_btm hide">
															<div className="credit-card-cvv">
																<input type="password" className="credit-card-cvv-input" onChange={e => this.handleCvvChange(e, p)} placeholder="Enter Cvv" onInput={e => this.onInputCvv(e)} maxLength="4"/> 
															</div>
															{
															card_list.digitalCardData?.status?.toLowerCase() == 'active' ?
															<div className="paynow_card" onClick={() => this.payWithReturnUserCard(card_list)}>Pay AED {this.props.state?.app_details?.grand_total}</div>
															:
															card_list.digitalCardData?.status?.toLowerCase() == 'expired' ?
															<div className="error_card_list_pay">This card is expired</div>
															:
															card_list.digitalCardData?.status?.toLowerCase() == 'suspended' ?
															<div className="error_card_list_pay">Something went wrong with this card. Please contact your bank.</div>
															:
															card_list.digitalCardData?.status?.toLowerCase() == 'pending' ?
															<div className="error_card_list_pay">This card is not accepted. Please try a different payment brand.</div>
															:
															<div className="error_card_list_pay" onClick={() => this.payWithReturnUserCard(card_list)}>Pay AED {this.props.state?.app_details?.grand_total}</div>
															}
															
														</div>
													</label>
												</div>
											)
										}, this)}
									</>:
											this.props.state.showCard === "apple_card_screen" ?(
												<>
													<div className="paymode_section">
														<div className="paymode_section_btn_warp">
															<a onClick={e => this.applePay()}>
																<img src={imagePath('./ApplePay_PayWith.png')} alt="" />
															</a>
														</div>
														<div className="paymode_section_split">OR</div>
														<div className="paymode_section_btn_warp">
															<a onClick={e => this.samsungPay()}>
																<img src={imagePath('./samsung.png')} alt="" />
															</a>
														</div>
														<div className="paymode_section_split">OR</div>	
														<div className="paymode_section_btn_warp">
															<a className="paymode_section_credit" onClick={e => this.props.setCountryDetail({ showCard: "card_screen" })}>Pay with Card</a>
														</div>
													</div>
												</>
												):(
												<>
													{this.props.state.showCard === "3d Redirect" &&
														<iframe id="foloosiPluginApiiframe" className="frame-open paytabs"></iframe>}
													{this.props.state.showCard === "ngeniusthreedsecure" &&
														<iframe id="foloosiPluginApiiframe" className="frame-open ngenius"></iframe>}
												</>
									)}
								</div>
								</>
								:
								<div onKeyPress={(event) => this.guesthandleKeyPress(event)}>
									<div className="fpl_detail">
										<input type="email" name="email" value={this.props.state.email || ''} onChange={e => { this.props.handleChange(e.target.name, e.target.value); this.handleGuestEvent(e.target.name) }} placeholder="Email"
												onKeyPress={e => this.handleGuestEvent(e.target.name)} />
											{
												this.props.state.errors.email &&
												<span className="error_message_bottom">
													{this.props.state.errors.email}
												</span>
											}
									</div>
									<div className="fpl_detail">
										<input type="text" name="name" value={this.props.state.name || ''} onChange={e => { this.props.handleChange(e.target.name, e.target.value); this.handleGuestEvent(e.target.name) }} placeholder="Full Name"
											onKeyPress={e => this.handleGuestEvent(e.target.name)} />
										{
											this.props.state.errors.name &&
											<span className="error_message_bottom">
												{this.props.state.errors.name}
											</span>
										}
									</div>
									<div className="fpl_detail">
										<div className="leftWrapContPhone">
											<span className="country_flag">
												{/* <div className="selected-flag" onClick={(event) => this.props.handleChange("countryFlag", !this.props.state.countryFlag)}> */}
												<div className="selected-flag" onClick={(e) => this.handlePhone(e)}>	
													<div className={"iti-flag " + selected_country_flag || ''}></div>
													<div className="iti-arrow"></div>
												</div>
											</span>
											<span className="phone_code">{selected_country_code || ''}</span>
											{this.props.state.countryFlag === true &&
												<PhoneCode showFirst={selected_country_code}
													defaultValue='select county'
													id='some-id'
													name='some-name'
													optionClassName='some option class name' />
											}
										</div>
										<div className="rightWrapContPhone">
											<input name="ph_number" type="tel"
												value={this.props.state.ph_number || ''}
												onChange={e => { this.props.handleChange(e.target.name, e.target.value); this.handleGuestEvent(e.target.name); }}
											onKeyPress={e => { this.handleKeyEvent(e); this.handleGuestEvent(e.target.name); }} onPaste={e => this.handlePaste(e)}
												placeholder="Phone" />
										</div>
										{
											this.props.state.errors.ph_number &&
											<span className="error_message_bottom">
												{this.props.state.errors.ph_number}
											</span>
										}
									</div>
									<>
									<div className="fpl_merchant_right_middle">
										<div className="fpl_merch_detail">Pricing Details</div>
										<div className="view">
                      {this.state.active === true ? (
                        <a onClick={this.handleClose}>Hide Details</a>
                      ) : (
                        <a onClick={this.handleActiveChange}>View Details</a>
                      )}
					
                    		</div>
									</div>
									
									<div className="width_100">
										<div className="filed_fpl space_right">
											<label className='fpl_lab' >Need To Pay</label>
										</div>
										<div className='filed_input filed_back'>
										<div className="field-wrapper">
											<div className='field-addon aed_align'>
											<span className="aed_aligns">
          											AED
													</span>
     										 	<span className="aed_align">
												{this.state.amount? this.state.amount.toFixed(2) : getdetails?.shipping_option==="Yes" ?
												getdetails.grand_total
											  : getdetails.grand_total}
										</span>
									</div>
    									

       						 <div className="field field--counter">
    					<div className="field-content">
							<div className="field-wrapper">
								<div className='counter-borter'>
								{this.state.qtyMinus ?
									<button  className='minsbut' type="button" onClick={() => this.qtyChange('decrease',this.state.data.reference_id)}>-</button>:
										<button disabled className='minsbut' type="button">-</button>
								}
   							 		<input className="field-el counter-value" size="1" type="number"value={this.state.count}/>
								{this.state.qtyPlus ?
									<button className='plusbut' type="button" onClick={() => this.qtyChange('increase',this.state.data.reference_id)}>+</button>:
										<button disabled className='plusbut' type="button">+</button>
								}
						 						</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
					{this.state.active &&(
										<>
										
									<div className="width_100">
										<div className="fpl_detail fpl_detail_50 space_right">
											<label className='fpl_lab'>Amount</label>
										</div>
										<div className='field-descr'>
										<div className="field-eldes">
										<span className="field-addon aed_align">
										<span className='aed_aligns'>AED</span> 
										<span className='aed_align'>{ getdetails.product_price.toFixed(2)}</span>
								</span>
							</div>
								</div>
									</div>
										{this.state.shipping_amount ? 
									<div className="width_100">
										<div className="fpl_detail fpl_detail_50 space_right">
											<label className='fpl_lab'>Shipping Fee</label>
										</div>
										<div className='field-descr'>
										<div className="field-eldes">
										
										<span className="field-addon aed_align ">
												<span className='aed_aligns'>AED</span> 
												<span className='aed_align'>{this.state.shipping_amount.toFixed(2)}</span>
										</span>
										
							</div>
								</div>
									</div>:""}
										{this.state.vat_amount?
									<div className="width_100">
										<div className="fpl_detail fpl_detail_50 space_right">
											<label className='fpl_lab'>VAT({getdetails?.user?.product_setting?.vat} %)</label>
										</div>
										<div className='field-descr'>
										<div className="field-eldes">
										
											<span className="field-addon aed_align">
											<span className='aed_aligns'>AED</span> 
											<span className='aed_align'>{this.state.vat_amount?.toFixed(2)}</span>
										</span>
									
							</div>
								</div>
									</div>:
									""}
									
									{this.state.order_tax.length > 0 && (
									this.state.order_tax.map((stcost,j)=>(
										
									<div className="width_100" key={j}>
									
										<div className="fpl_detail fpl_detail_50 space_right">
											<label className='fpl_lab'>
												{stcost.tax_name} ({stcost.tax_value}{stcost.tax_type == "percentage" ? "%" :""})
											</label>
										</div>
										<div className='field-descr'>
											<div className="field-eldes">
												<span className="field-addon aed_align">
												<span className='aed_aligns'>AED</span> 
												<span className='aed_align'>{stcost.tax_amount.toFixed(2)}</span>
												</span>
											</div>
										</div>
									</div>
									))
									)
									}

									{this.state.shipping_amount ? "":
									this.state.vat_amount?"":
									this.state.order_tax.length > 0 ?"":
										 getdetails?.order_taxes?.map((gstcost,i)=>(
											<div className="width_100" key={i}>
											<div className="fpl_detail fpl_detail_50 space_right">
												<label className='fpl_lab'>
													{gstcost.tax_name}
												</label>
											</div>
											<div className='field-descr'>
											<div className="field-eldes">
											
												<span className="field-addon aed_align">
												<span className='aed_aligns'>AED</span> 
												<span className='aed_align'>{gstcost.tax_value}</span>
											</span>
										</div>
										</div>
										</div>
										))}
									
										
									{getdetails.inclusive_tax==="Yes"?
										<div className="width_100">
										<div className="">
										{getdetails.language==="EN"?<label className='fpl_lab'>Note: Inclusive of taxes</label>
										:<label className='fpl_lab'>Note:  شامل الضرائب</label>}
									</div>
									</div>:""}
									</>)}
								<>
									<div className="fpl_merchant_right_middle">
										<div className="fpl_merch_detail">Billing Details</div>
									</div>
									<div className="width_100">
										<div className="fpl_detail fpl_detail_50 space_right">
											<label>Flat No.</label>
											<input type="text" name="billing_flat_no" placeholder="Flat No."
												value={this.props.state.billing_flat_no}
												onChange={e => this.handleChange(e)}
												style={{ borderColor: this.props.state.valid_billing_flat_no ? 'red' : '#e1e1e1' }} tabIndex="5" />
											{this.props.state.payment_error.billing_flat_no &&
												<span
													className="error_message_bottom">
													{this.props.state.payment_error.billing_flat_no}
												</span>}
										</div>
										<div className="fpl_detail fpl_detail_50 space_left">
											<label>Billing Address</label>
											<input type="text" name="billing_address" placeholder="Billing Address"
												value={this.props.state.billing_address}
												onChange={e => this.handleChange(e)}
												style={{ borderColor: this.props.state.valid_billing_address ? 'red' : '#e1e1e1' }} tabIndex="5" />
											{this.props.state.payment_error.billing_address &&
												<span
													className="error_message_bottom">
													{this.props.state.payment_error.billing_address}
												</span>}
										</div>
										<div className="fpl_detail fpl_detail_50 space_right">
											<label>City</label>
											<input type="text" name="billing_city"
												onChange={e => this.handleChange(e)}
												value={this.props.state.billing_city} placeholder="Enter City"
												style={{ borderColor: this.props.state.valid_billing_city ? 'red' : '#e1e1e1' }} tabIndex="5" />
											{this.props.state.payment_error.billing_city &&
												<span
													className="error_message_bottom">
													{this.props.state.payment_error.billing_city}
												</span>}
										</div>
										<div className="fpl_detail fpl_detail_50 space_left">
											<label>Country</label>
											<Select className="pay_popup_card_country"
												isSearchable
												options={countryList}
												value={this.props.state.selectedCountry}
												onChange={e => this.props.setCountryDetail({
													selectedCountry: e,
													state:''
												})}
												maxMenuHeight={'170px'}
												styles={customStyles}
											/>
										</div>
										<div className="fpl_detail fpl_detail_50 space_right">
											<label>State</label>
											{
												this.props.state.selectedCountry.value === "USA" || this.props.state.selectedCountry.value === "CAN" ?
												<>
													<Select className={this.props.state.valid_state === true ? "pay_popup_card_country pay_popup_card_country_error" : "pay_popup_card_country"}
														isSearchable
														options={StateNames}
														value={this.props.state.selectedState || ""}
														onChange={e => this.handleState({ state: e.value, valid_state: false, selectedState: e })}
														//onChange={e => this.props.setCountryDetail({ state: e.value, valid_state: false, selectedState: e })}
														maxMenuHeight={'170px'}
														styles={customStyles}
														menuPlacement="top"
													/>
													{this.props.state.payment_error.state &&
													<span
														className="error_message_bottom">
														{this.props.state.payment_error.state}
													</span>}
												</>
												:
												<>
												<input type="text" name="state"
													onChange={e => this.handleChange(e)}
													value={this.props.state.state} placeholder="Enter State" 
													style={{ borderColor: this.props.state.valid_state ? 'red' : '#e1e1e1' }}
													tabIndex="5" />
													{this.props.state.payment_error.state &&
													<span
														className="error_message_bottom">
														{this.props.state.payment_error.state}
													</span>}
												</>
											}
										</div>
										<div className="fpl_detail fpl_detail_50 space_left">
											<label>Postal Code</label>
											{
												this.props.state.selectedCountry.value === "USA" &&
												<>
													<Cleave
														options={
															{
																delimiter: '-',
																blocks: [5, 4],
																numericOnly: true
															}
														}
														onChange={this.postalCodeChange}
														placeholder="Eg. 12345-6789"
														className="pay_popup_card_input"
														style={{ borderColor: this.props.state.valid_postal_code ? 'red' : '#e1e1e1' }} />
													{this.props.state.payment_error.postal_code &&
													<span
														className="error_message_bottom">
														{this.props.state.payment_error.postal_code}
													</span>
													}
												</>
											}
											{
												this.props.state.selectedCountry.value === "CAN" &&
												<>
													<Cleave
														options={
															{
																delimiter: ' ',
																blocks: [3, 3],
																uppercase: true
															}
														}
														onChange={this.postalCodeChange}
														placeholder="Eg. A1B 2C3"
														className="pay_popup_card_input"
														style={{ borderColor: this.props.state.valid_postal_code ? 'red' : '#e1e1e1' }} />
													{this.props.state.payment_error.postal_code &&
													<span
														className="error_message_bottom">
														{this.props.state.payment_error.postal_code}
													</span>
													}
												</>
											}
											{
												this.props.state.selectedCountry.value === "CAN" || this.props.state.selectedCountry.value === "USA"
												?
												null
												:
												<>
													<input type="text" name="postal_code" placeholder="Enter Postal Code"
														value={this.props.state.postal_code}
														onChange={e => this.handleChange(e)}
														style={{ borderColor: this.props.state.valid_postal_code ? 'red' : '#e1e1e1' }} tabIndex="5" />
													{this.props.state.payment_error.postal_code &&
													<span
														className="error_message_bottom">
														{this.props.state.payment_error.postal_code}
													</span>
													}
												</>
											}
											
										</div>
									</div>
								
									</>
									<div className="fpl_detail_btn">
										{/*(!this.props.state.show_address) && this.props.state.valid_shipping_address === false && this.props.state.valid_shipping_city === false && this.props.state.valid_shipping_state === false && this.props.state.valid_shipping_postal_code === false && this.props.state.valid_billing_address === false && this.props.state.valid_city === false && this.props.state.valid_state === false && this.props.state.valid_postal_code === false ?
											<button onClick={(event) => this.guestLogin(event)}>PROCEED TO PAY</button> :
										this.props.state.valid_billing_address === false && this.props.state.valid_city === false &&
											this.props.state.valid_state === false && this.props.state.valid_postal_code === false ?
											<button onClick={(event) => this.guestLogin(event)}>PROCEED TO PAY</button>
											:
											<button className="pay_popup_submit_disable" onClick={e => this.showErrors()}>PROCEED TO PAY</button>
										*/}
										<button onClick={(event) => this.guestLogin(event) }>PROCEED TO PAY</button>
										
									</div>
								</div>
							}
							</>
							:
							<div className="width_100 text-center">
								<img src={imagePath("./loader.gif")} alt="" />
							</div>
							}
						</div>:
					
					<div className="status_bold status_failure">Link expired!!.Please contact {getdetails?.user?.business_name}</div>
					}
					{(this.props.state.paymentframe === true) ?
						null
						:
						this.state.status?<div className="flp_pay_detail">
							<div className="flp_pay_detailWrap">
							{getdetails.language==="EN"?<div className="flp_pay_detailTxt">Payment Details</div>
							:<div className="flp_pay_detailTxt">بيانات الدفع							</div>}
								<div className="flp_pay_detailCard">Amex, Visa & Mastercard</div>
								<img src={imagePath('./arrow.png')} alt="" className="flp_pay_arrow"/>
							</div>
						</div>
						:""
						}
						<div className="fpl_merchant_rightBot">
							<img className="pci_icon" src={imagePath('./pci.png')} alt=""/>
							<img src={imagePath('./visa.png')} alt=""/>
							<img src={imagePath('./master.png')} alt=""/>
							<img src={imagePath('./amex.png')} alt=""/>
						</div>
	
					</div>
					</div>
					</>
					</div>:""}
					
					{this.state.availableStatus?<div className='status_bold'>Product is not available</div>:""}
				</div>
				
				
				<Footer/>
			</div>
			</>
		);
	}

}
const mapStateToProps = state => {
	console.log("state.AppReducer",state.AppReducer);
	return {
		state: state.AppReducer
	}
};

const mapDispatchToProps = dispatch => ({
	getDetails: (response) => { dispatch({ type: GET_DETAILS, payload: response }); return Promise.resolve(); },
	currentcountry: (payload) => { dispatch({ type: CURRENT_COUNTRY, payload }); return Promise.resolve(); },
	setCountryDetail: (payload) => { dispatch({ type: COUNTRY_DETAIL, payload }); return Promise.resolve(); },
	handleChange: (name, value) => dispatch({ type: CHANGE_VALUE, payload: { name, value } }),
	errorChange: (payload) => dispatch({ type: ERROR_CHANGE, payload }),
	updateCredential: (payload) => { dispatch({ type: UPDATE_HEADER, payload }); return Promise.resolve(); },
	setError: (payload) => { dispatch({ type: SET_ERROR, payload }); return Promise.resolve(); },
	checkChange: (payload) => { dispatch({ type: CHECK_CHANGE, payload }); return Promise.resolve(); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);    