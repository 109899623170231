import React, { Component } from 'react';
import { connect } from 'react-redux';
import {imagePath} from '../actions/ImagePath';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import Home from '../Components/Home';
import App from '../Container/App';
import { Route, Switch,BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';

class Cancel extends Component {
    constructor (props) {
        super(props);
        var app_details = window.parent.app_details;
        //var transaction_number = window.parent.transaction_no;
        this.state = {
            app_details:app_details,
           // transaction_number:transaction_number,
            payment_transaction_id:"",
            reference_id:"",
            transaction_no:""
        }
        this.goBack = this.goBack.bind(this);
    }
    goBack(){
        window.top.location.reload();
    }
    
    componentDidMount() {
        var _this=this;
        var getUrlParams = new URLSearchParams(window.location.search);
        //var urlParams = JSON.parse(getUrlParams.get('q'));
        _this.setState({transaction_no:getUrlParams.get('transaction_no')}, () => {
            setTimeout(() => {
                var dataError = {"status":"error","data": {"payment_status": "Transaction Cancelled"}}
                window.top.parent.postMessage(dataError, "*")
            }, 2000);
        });
    }
    render() {
        return (
            <div className="fpl_merchant_rightMid text-center bg_remove">
                <div className="status_heading">Payment Cancelled</div>
                <p className="status_trans_id">Payment ID: #{this.state.transaction_no}</p>
                <div className="status_trans_icon">
                    <img className="successPayimg" src={imagePath('./cancel.svg')} />
                </div>
                <div className="status_trans_message">Note : If the amount is deducted from your card. It will be refunded immediately. Please contact your bank for support.</div>
                <div className="fpl_mob_wrap"><a onClick={()=>this.goBack()} className="status_error_btn">Try Again</a></div>
            </div>
        );
    }
}
          
const mapStateToProps = state => {
	return {
		state : state.AppReducer
	}
};
          
export default connect(mapStateToProps)(Cancel);  