export const APP_LOAD = 'APP_LOAD';
export const GET_DETAILS = 'GET_DETAILS';
export const CURRENT_COUNTRY = 'CURRENT_COUNTRY';
export const COUNTRY_DETAIL = 'COUNTRY_DETAIL';
export const CHANGE_VALUE = 'CHANGE_VALUE';
export const ERROR_CHANGE = 'ERROR_CHANGE';
export const UPDATE_HEADER = 'UPDATE_HEADER';
export const SET_ERROR = 'SET_ERROR';
export const CHECK_CHANGE = 'CHECK_CHANGE';
export const SHOW_COUNTRY = 'SHOW_COUNTRY';
export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const GET_APP_DETAILS = 'GET_APP_DETAILS';