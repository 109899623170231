import {
  APP_LOAD,
  GET_DETAILS,
  CURRENT_COUNTRY,
  COUNTRY_DETAIL,
  CHANGE_VALUE,
  ERROR_CHANGE,
  UPDATE_HEADER,
  SET_ERROR,
  CHECK_CHANGE,
  SELECT_COUNTRY,
  SHOW_COUNTRY
} from '../constants/actionTypes';

const defaultState = {
  loading: true,
  app_details:{},
  current_country:{},
  viewscreen:"",
  paymentframe:false,
  countryFlag:false,
  disableguestPay:false,
  errors:{},
  headers : {
    'access_for':'pay_link'
  },
  selectedCountry : {},
  shippingSelectedCountry:{},
  payment_error:{},
  show_address:true,
  billing_flat_no:"",
  billing_address:"",
  billing_city:"",
  state:"",
  postal_code:"",
  shipping_flat_no:"",
  shipping_address:"",
  shipping_city:"",
  shipping_state:"",
  shipping_postal_code:"",
  shipping_address:"",
  click2pay_agree:true,
  click2pay_agree_card:false,
  click2pay:false,
  dpa_id:"",
  validCardName:false,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {        
        ...state,
        loading: false
      };  
    case GET_DETAILS:
      return {        
        ...state,
        app_details : action.payload.data,
        click2pay:action.payload.data?.user?.c2p_enabled,
        dpa_id:action.payload.data?.user?.c2p_dpa_id,
        
      };  
    case CURRENT_COUNTRY:
      return {        
        ...state,
        current_country : {
          "as": action.payload.as,
          "city": action.payload.city ? action.payload.city : 'Dubai',
          "country": action.payload.country,
          "countryCode": action.payload.countryCode ? action.payload.countryCode : 'AE',
          "isp": action.payload.isp,
          "lat": action.payload.lat,
          "lon": action.payload.lon,
          "org": action.payload.org,
          "query": action.payload.query,
          "region": action.payload.region,
          "regionName": action.payload.regionName ? action.payload.regionName : 'Dubai',
          "status": action.payload.status,
          "timezone": action.payload.timezone,
          "zip": action.payload.zip ? action.payload.zip : '000000'
        }
      }; 
    case COUNTRY_DETAIL:
      return {        
        ...state,...action.payload
      };
    case CHANGE_VALUE:
      return {        
        ...state,
        [action.payload.name] : action.payload.value
      }; 
    case ERROR_CHANGE:
      return {        
        ...state,
        errors : {
          ...state.errors,
          ...action.payload
        }
      }; 
    case UPDATE_HEADER:
      return {        
        ...state,
        headers :{
          ...state.headers,
          ...action.payload
        }
      }; 
    case SET_ERROR:
      return {
        ...state,
        payment_error : {
          ...state.payment_error,
          ...action.payload
        }
    }; 
    case CHECK_CHANGE:
    return {        
      ...state,
      ...action.payload
    }; 
    case SHOW_COUNTRY:
      return {        
      ...state,
      countryFlag : !state.countryFlag
    };
    case SELECT_COUNTRY:
      return {        
        ...state,
        selected_country : {
          ...state.selected_country,
          country_code : action.payload.code,
          country_name : action.payload.name,
          country_flag : action.payload.flag,
          countryRegion : action.payload["2digit"],
        }          
    };   
    default : return state;  
  }
}

